import { IconPageNotFound } from '@/Components/Icons/IconPageNotFound';
import { IconLinkIsNotValid } from '@/Components/Icons/IconLinkIsNotValid';
import { IconAccessDenied } from '@/Components/Icons/IconAccessDenied';
import { IconSharableElementNotFound } from '@/Components/Icons/IconSharableElementNotFound';

const CUSTOM_SCREEN_QUERY_PARAMS_KEYS = {
  SOURCE: 'source',
} as const;

export const CUSTOM_SCREEN_QUERY_PARAMS = {
  [CUSTOM_SCREEN_QUERY_PARAMS_KEYS.SOURCE]: {
    key: CUSTOM_SCREEN_QUERY_PARAMS_KEYS.SOURCE,
    values: {
      WIZARD: 'wizard',
    },
  },
} as const;

export const CUSTOM_SCREEN_SEARCH_TYPES = {
  SEMANTIC: 'semantic',
} as const;

export const ROUTES = {
  ROOT: '/',
  COMPANIES_LIST: '/search-list',
  SAVED_SEARCH: '/saved-search',
  SEARCH_LISTS: '/search-lists',
  CUSTOM_SEARCH: '/customScreen',
  CUSTOM_SEARCH_SEMANTIC: `/customScreen/${CUSTOM_SCREEN_SEARCH_TYPES.SEMANTIC}`,
  COMPANY_PROFILE: '/company-profile',
  COMPANY_FINDER: '/company-finder',
  CREATE_LIST_WORKFLOW: '/create-list-workflow',
} as const;

export const HOME_PAGE_SLUG = ROUTES.SEARCH_LISTS;
export const HELIX_LANDING_PAGE_URL = 'https://helix.bain.io';
export const MUI_LICENSE_KEY =
  'bc84615d99d6e3841071aa1c6aeb3f48T1JERVI6NDIzNzMsRVhQSVJZPTE2ODIyNzM0MDcwMDAsS0VZVkVSU0lPTj0x';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = process.env.REACT_APP_ENV === 'prod';

export const SHOULD_MOCK_API_IN_BROWSER = false;
export const MOCK_API_IN_BROWSER = SHOULD_MOCK_API_IN_BROWSER && IS_DEV;

export const HIDE_FILTERS_COUNT = JSON.parse(process.env.REACT_APP_HIDE_FILTERS_COUNT ?? 'true');
export const HIDE_SIMILAR_COMPANIES = JSON.parse(process.env.REACT_APP_HIDE_SIMILAR_COMPANIES ?? 'true');
export const HIDE_SEMANTIC_SEARCH = JSON.parse(process.env.REACT_APP_HIDE_SEMANTIC_SEARCH ?? 'true');

/** Need to update TS version */
// @ts-ignore
export const CURRENCIES_SET = new Set(Intl.supportedValuesOf('currency'));

export const MODALS = {
  DATA_EXPORT: 'dataExport',
  COMPARISON_EXPORT: 'comparisonExport',
  ADVANCED_SEARCH: 'advancedSearch',
  SEARCH_COMPANIES_HISTORY: 'companySearchHistory',
  CASE_CODE: 'caseCode',
  CREATE_COMPANIES_LIST: 'createCompaniesList',
  UPDATE_COMPANIES_LIST: 'updateCompaniesList',
  CREATE_SAVED_SEARCH: 'createSavedSearch',
  UPDATE_SAVED_SEARCH: 'updateSavedSearch',
  SIMILAR_COMPANIES_LOADING_DIALOG: 'similarCompanies',
  WIZARD_LOADING_DIALOG: 'wizardLoadingDialog',
  SHARE_LIST_WITH_COLLABORATORS: 'shareListWithCollaborators',
  COMPANIES_LIST_HISTORY: 'companiesListHistory',
  STARTER_PACK_WIZARD: 'starterPackWizard',
  SEMANTIC_SEARCH_LOADING_DIALOG: 'semanticSearchLoadingDialog',
  CONFIRM_DIALOG: 'confirmDialog',
};

export const SPECIAL_SEARCH_WORDS = ['NOT', 'AND', 'OR'];
export const SPECIAL_SEARCH_CHARS = ['?', '*', '~', '(', ')'];
export const QUOTES = ["'", '"'];

export const CURRENCIES = {
  USD: { value: 'usd', label: '$' },
  EUR: { value: 'euro', label: '€' },
};

export const HELIX_APPS = {
  FIND: { label: 'Find', link: 'https://find.helix.bain.io/' },
  LEARN: { label: 'Learn', link: 'https://insights.helix.bain.io/?page=CompanyOverview_v2' },
  SIZE: { label: 'Size', link: 'https://insights.helix.bain.io/?page=MarketSizing' },
  COMPETITOR_FINDER: { label: 'Competitor Finder', link: 'https://competitorfinder.helix.bain.io/' },
  CENTRE_OF_EXCELLENCE: {
    label: 'Centre of Excellence',
    link: 'https://iris-sites.bain.com/private-equity/priorities/helix/helix-coe/?contentTabs=home',
  },
};

export const LOCAL_STORAGE_KEYS = {
  QUERIES_HISTORY: 'queriesHistory',
  REDIRECT_URL: 'redirectUrl',
  CASE_CODE: 'caseCode',
  UI_SECRETS: 'uiSecrets',
  COMPANIES_LISTS_SHARED_WITH_USER: 'sharedWithMeCompaniesLists',
  SEARCHES_SHARED_WITH_USER: 'sharedWithMeSearches',
  WELCOME_TO_HELIX: 'welcomeToHelix',
};

export const HISTORY_KEYS = {
  CUSTOM_HISTORY: 'customHistory',
  SCREENERS_HISTORY: 'screenersHistory',
};

export const FILTER_TYPES = {
  NUMERICAL: 'numerical',
  TREE: 'tree',
  CATEGORICAL: 'categorical',
  MULTIPLE_SELECT: 'multiple-select',
} as const;

type FilterTypesKeys = keyof typeof FILTER_TYPES;

export type FilterTypesTypes = typeof FILTER_TYPES[FilterTypesKeys];

export const FILTER_TYPES_ARRAY = Object.values(FILTER_TYPES);

export const QUERIES_HISTORY_EXPIRATION = 90; // days
export const MAX_SEARCH_HISTORY_QUERIES = 1000;

export const DEFAULT_PAGE_SIZE = 20;

export const CHECK_COLUMN_NAME = '__check__';

export const DEFAULT_VISIBLE_COLUMNS = [
  'self_firmo_name___',
  'self_firmo_description___',
  'self_firmo_webpage___',
  'self_location_country___',
  'self_sector_industry___',
  'self_firmo_employeeCount___',
  'self_financials_ebitda___',
  'self_financials_netIncome___',
  'self_financials_revenue___',
  'self_financials_revenueCagr_1yr__',
];
export const EXTENDED_DEFAULT_COLUMNS = [CHECK_COLUMN_NAME, ...DEFAULT_VISIBLE_COLUMNS];

export const DEFAULT_ERROR_MESSAGE = "Something's not quite right with your search.";
export const MAX_COMPANIES_ON_THE_LIST = 10000;
export const MAX_COMPANIES_EXPORT = 10000;
export const FILTERS_ID = 'company-filters';

export const NUMERIC_FILTER_TYPES = {
  YEARS: 'year',
  PERCENTAGE: 'percent',
  EUR: 'EUR',
  USD: 'USD',
  CURRENCY: 'currency',
  DATE: 'date',
  OTHER: 'count',
};

export const SEARCH_EVENTS = {
  CHANGE_FILTERS: 'change-filters',
  REMOVE_QUERY: 'remove-query',
  REMOVE_QUERY_SMART_SEARCH: 'remove-query-smart-search',
  HISTORY: 'history',
};

export const HEADER_HEIGHT = 48;

export const ERROR_PAGE_PROPS = {
  LINK_IS_NOT_VALID: {
    title: 'Link is not valid any more',
    description: 'The links are set to expire after a certain amount of time',
    Icon: IconLinkIsNotValid,
  },
  PAGE_NOT_FOUND: {
    title: 'Page not found',
    description: "We can't seem to find the page you were looking for.",
    Icon: IconPageNotFound,
  },
  ACCESS_DENIED: {
    title: 'Access denied',
    description: "You don't have permission to view this page",
    Icon: IconAccessDenied,
  },
  LIST_NOT_FOUND: {
    title: 'List not found',
    description: 'This list may have been deleted or removed',
    Icon: IconSharableElementNotFound,
  },
  SAVED_SEARCH_NOT_FOUND: {
    title: 'Search not found',
    description: 'This search may have been deleted or removed',
    Icon: IconSharableElementNotFound,
  },
  COMPANY_PROFILE_NOT_FOUND: {
    title: 'Company profile not found',
    description: 'The URL contains not valid BainId or the server is unavailable',
    Icon: IconSharableElementNotFound,
  },
  ERROR_BOUNDARY: {
    title: 'Woops! Looks like something went wrong',
    description: 'Please return to homepage',
    Icon: IconSharableElementNotFound,
  },
};

//CASE CODE

const CASE_CODE_DEFAULT_ERROR_MESSAGE =
  'Enter a valid case code. Helix is a free tool, so this is just for tracking purposes.';

const CASE_CODE_VALIDATION = {
  required: true,
  maxLength: { value: 5, message: CASE_CODE_DEFAULT_ERROR_MESSAGE },
  minLength: { value: 3, message: CASE_CODE_DEFAULT_ERROR_MESSAGE },
  validate: {
    notTest: (value: string) => (!value?.toLowerCase().includes('test') ? true : CASE_CODE_DEFAULT_ERROR_MESSAGE),
    notSameChars: (value: string) => (/^(.)\1+$/.test(value) ? CASE_CODE_DEFAULT_ERROR_MESSAGE : true),
    notSpecialChars: (value: string) => (/[^a-zA-Z0-9]/.test(value) ? CASE_CODE_DEFAULT_ERROR_MESSAGE : true),
  },
};

const CASE_CODE_INPUT_FIELDS = {
  CASE_CODE: {
    key: 'caseCode',
    label: 'Case code - this is just for tracking',
    dataTestid: 'case-code-input',
    validation: CASE_CODE_VALIDATION,
    requiredValidationMessage: CASE_CODE_DEFAULT_ERROR_MESSAGE,
  },
  OTHER: {
    key: 'otherInfo',
    label: 'How are you using Helix?',
    validation: {
      required: true,
    },
    requiredValidationMessage: 'Enter a reason for using Helix',
    asTextArea: true,
  },
};

export const CASE_CODE_FORM_STEPS = {
  FIRST: 'first',
  SECOND: 'second',
};

export const CASE_CODE_FLOWS = {
  CASE_WORK: {
    value: 'CASE_WORK',
    label: 'Case work',
    [CASE_CODE_FORM_STEPS.FIRST]: [CASE_CODE_INPUT_FIELDS.CASE_CODE],
    [CASE_CODE_FORM_STEPS.SECOND]: [CASE_CODE_INPUT_FIELDS.OTHER],
  },
};

export const CASE_CODE_FLOW_TYPES = [CASE_CODE_FLOWS.CASE_WORK];

export const CHART_SECTIONS = {
  FINANCIALS: 'Financials',
  WEB_TRAFFIC: 'Web Traffic',
};

export const DATA_SOURCE_IDS = {
  self_ids_id__ciq_: 'CapIQ',
  self_ids_id__cb_: 'Crunchbase',
  self_ids_id__zi_: 'ZoomInfo',
  self_ids_id__gp_: 'Gainpro',
  self_ids_id__bq_: 'Brightquery',
  self_ids_id__arx_: 'Arx',
  self_ids_id__helix_connect_: 'SPS',
  self_ids_id__bdex_: 'BoardEx',
  self_ids_id__factiva_: 'Factiva',
  self_ids_id__pdl_: 'People Data Labs',
  self_ids_id__delphai_: 'Delphai',
  self_ids_id__aura_: 'Aura',
} as const;

export type DATA_SOURCE_ID = keyof typeof DATA_SOURCE_IDS;

export const CHART_COMPARE_LIMIT = 4;