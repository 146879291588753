import React from 'react';

import { Skeleton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { range } from 'lodash';
import { StepProps } from '../types';

import { WorkflowDialogHeader } from '../../Common/WorkflowDialogHeader';
import { WorkflowDialogFooter } from '../../Common/WorkflowDialogFooter';
import { actions as searchActions } from '../../../../slices/search';
import { useModal } from '@/hooks/use-modal';
import { MODALS, ROUTES } from '@/constants';
import { useQueryRefineSemanticDescription } from '@/hooks/queries/semantic-search/use-query-refine-semantic-description';

export const AiRefinedSemanticSearchDescriptionStep = ({
  handleClose,
  setStep,
  isFirstStep,
  progress,
  userDescription,
}: StepProps) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { handleOpen: openLoadingModal } = useModal(MODALS.SEMANTIC_SEARCH_LOADING_DIALOG);

  const onBack = () => {
    if (isFirstStep) {
      handleClose();

      return;
    }

    setStep((prevState) => prevState - 1);
  };

  const { data, isLoading: isSemanticRefiningLoading } = useQueryRefineSemanticDescription({
    description: userDescription ?? '',
    enabled: !!userDescription,
    onError: onBack,
  });

  const refinedDescription = data?.improved_description;

  const onNext = () => {
    if (!refinedDescription) return;

    dispatch(searchActions.setSemanticSearch({ userDescription: refinedDescription, applyDescription: false }));
    handleClose();
    push(ROUTES.CUSTOM_SEARCH_SEMANTIC);
    openLoadingModal();
  };

  return (
    <>
      <div className="mx-auto w-1/3 min-w-[650px] text-sm font-normal text-[#484848] flex flex-col flex-grow">
        <WorkflowDialogHeader title="Refining your description" />
        {isSemanticRefiningLoading ? (
          <div>
            {range(4).map((idx) => (
              <Skeleton
                key={idx}
                variant="rounded"
                className="bg-[#0000000f] rounded-2xl my-4"
                height={60}
              />
            ))}
          </div>
        ) : (
          <>
            <span className="mb-10">
              We&apos;ve applied AI to refine your description, we do this to ensure you get the best results.
            </span>

            {refinedDescription ? (
              <p className="border border-[#ddd] bg-[#FAFAFA] p-6 text-[#484848] text-sm leading-relaxed">
                {refinedDescription}
              </p>
            ) : null}
          </>
        )}
      </div>
      <WorkflowDialogFooter
        onPrimaryButtonClick={onNext}
        onBackButtonClick={onBack}
        isLoading={isSemanticRefiningLoading}
        progress={progress}
        primaryButtonId="provide-semantic-search-description-step-next-button"
      />
    </>
  );
};
