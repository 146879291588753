import React from 'react';

import { Tooltip } from '@mui/material';
import { FilterTree, TREE_TOP_LEVEL_NODE_VALUE } from './FilterTree';
import { FilterCheckboxList } from './FilterCheckboxList';
import { FilterNumerical } from './FilterNumerical';
import { getHighlightedText } from './get-highlighted-text';
import { FilterMultipleSelectChip } from './FilterMultipleSelectChip';
import { FILTER_TYPES, FilterTypesTypes } from '@/constants';
import { FilterUnit } from '@/types';
import { FilterStatsData } from '@/types/api/stats';
import { IFilterTreeData } from '@/types/state/filter-tree';
import { TFilterNumerical } from '@/types/state/filter-numerical';
import { IFilterCategoricalData } from '@/types/state/filter-categorical';
import { IFilterMultipleSelectData } from '@/types/state/filter-multiple-select';
import { IconInfo } from '@/Components/Icons/IconInfo';
import { SafeHTML } from '@/Components/Shared/SafeHTML/SafeHTML';

export const getAllCount = (items: IFilterTreeData[]) => items.reduce((acc, current) => acc + current.active, 0);

interface IFilterItem {
  isFetching: boolean;
  itemId: string;
  itemName: string;
  itemType: FilterTypesTypes;
  itemUnit: FilterUnit;
  filterItems: FilterStatsData | null;
  categoryFilterId: string;
  searchValue: string;
  withoutCounts?: boolean;
  categoricalAsDropdown?: boolean;
  hideNumericalToggle?: boolean;
  tooltip?: string;
  inWizard?: boolean;
}

export const FilterItem = ({
  isFetching,
  itemId,
  itemName,
  itemType,
  itemUnit,
  filterItems,
  categoryFilterId,
  searchValue,
  withoutCounts,
  categoricalAsDropdown,
  hideNumericalToggle,
  tooltip,
  inWizard,
}: IFilterItem) => {
  const { text, isHighlighted } = getHighlightedText({
    text: itemName,
    textToHighlight: searchValue,
  });

  let content = null;

  if (itemType === FILTER_TYPES.TREE) {
    content = (
      <FilterTree
        data={[
          {
            _backend: itemId,
            value: TREE_TOP_LEVEL_NODE_VALUE,
            active: getAllCount(filterItems as IFilterTreeData[]),
            children: filterItems,
            categoryFilterId,
          },
        ]}
        isFetching={isFetching}
        itemId={itemId}
        searchValue={searchValue}
        isFilterTitleHighlighted={isHighlighted}
        withoutCounts={withoutCounts}
        bordered={inWizard}
      />
    );
  }

  if (itemType === FILTER_TYPES.NUMERICAL) {
    content = (
      <div>
        {filterItems && (
          <FilterNumerical
            values={filterItems as TFilterNumerical}
            type={itemUnit}
            itemId={itemId}
            hideToggle={hideNumericalToggle}
          />
        )}
      </div>
    );
  }

  if (itemType === FILTER_TYPES.CATEGORICAL) {
    const filters = filterItems as IFilterCategoricalData[];

    content = (
      <div>
        {!!filters?.length && (
          <FilterCheckboxList
            itemId={itemId}
            values={filters}
            isFetching={isFetching}
            searchValue={searchValue}
            withoutCounts={withoutCounts}
            asDropdown={categoricalAsDropdown}
          />
        )}
      </div>
    );
  }

  if (itemType === FILTER_TYPES.MULTIPLE_SELECT) {
    const filters = filterItems as IFilterMultipleSelectData[];

    content = (
      <div>
        {!!filters?.length && (
          <FilterMultipleSelectChip
            itemId={itemId}
            values={filters}
            isFetching={isFetching}
            searchValue={searchValue}
            withoutCounts={withoutCounts}
          />
        )}
      </div>
    );
  }

  return content ? (
    <div
      data-testid="filter-item"
      className="mt-3"
    >
      <div
        className="font-semibold text-sm flex items-center"
        id={`${itemId}-header`}
      >
        {text}
        {tooltip ? (
          <Tooltip title={<SafeHTML>{tooltip}</SafeHTML>}>
            <span>
              <IconInfo
                className="w-[18px] ml-2"
                fill="#231F20"
              />
            </span>
          </Tooltip>
        ) : null}
      </div>
      {content}
    </div>
  ) : null;
};
