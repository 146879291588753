import { CURRENCIES } from '@/constants';
import { AxisFormat, ChartConfigType, Currency } from '@/types';
import { Tab as TabType } from '@/Components/CompanyResearch/CompanyProfile/types';
import { numberFormatterCustom } from '@/Components/Shared/common';

type AxisFormatType = { value: string | number; yAxisType?: AxisFormat; currency: Currency };

export const formatNumericChartValue = ({ value, yAxisType, currency }: AxisFormatType) => {
  switch (yAxisType) {
    case 'currency':
      return `${CURRENCIES[currency].label}${numberFormatterCustom(Number(value))}`;
    case 'numeric':
      return numberFormatterCustom(Number(value));
    case 'percent':
      return `${Number(value).toFixed(2)}%`;

    default:
      return value;
  }
};

export const getTabsWithCharts = (tabs: TabType[], chartConfigs: ChartConfigType[]) => {
  const sections = Array.from(new Set(chartConfigs.map((config) => config.SECTION)));

  const modifiedTabs = tabs.map((tab) => {
    if (sections.includes(tab.label)) {
      return {
        ...tab,
        components: [{ component: 'Charts' }, ...(tab.components ?? [])],
      };
    }

    return tab;
  });

  return modifiedTabs;
};
