import React from 'react';

import { useHistory } from 'react-router';
import { SEMANTIC_OPTION_STEPS, StepProps } from '../types';

import { WorkflowDialogHeader } from '../../Common/WorkflowDialogHeader';
import { WorkflowDialogFooter } from '../../Common/WorkflowDialogFooter';
import { HOME_PAGE_SLUG, MODALS, ROUTES } from '@/constants';
import { BetaTag } from '@/Components/Shared/BetaTag/BetaTag';
import { useModal } from '@/hooks/use-modal';

interface OptionButtonProps {
  id: string;
  title: string;
  subtitle: string;
  onClick: () => void;
  isBeta?: boolean;
}

const OptionButton = ({ id, title, subtitle, onClick, isBeta }: OptionButtonProps) => (
  <button
    onClick={onClick}
    className="px-6 py-5 border border-[#ddd] flex flex-col rounded-lg w-full hover:bg-[#2E3F4C] group transition relative"
    id={id}
  >
    <span className="text-base font-medium mb-1 group-hover:text-white text-left">{title}</span>
    <span className="text-sm text-[#666] group-hover:text-white text-left">{subtitle}</span>
    {isBeta ? (
      <div className="absolute right-4 top-4">
        <BetaTag />
      </div>
    ) : null}
  </button>
);

export const PickCreateListOptionStep = ({ handleClose, setStep, setStepsSet, progress }: StepProps) => {
  const { push } = useHistory();
  const { handleOpen: handleOpenNewCompaniesListDialog } = useModal(MODALS.CREATE_COMPANIES_LIST);

  const onSemanticOptionClick = () => {
    setStepsSet(SEMANTIC_OPTION_STEPS);
    setStep((prevState) => prevState + 1);
  };

  const onUploadIdsOptionClick = () => {
    push(HOME_PAGE_SLUG);
    handleOpenNewCompaniesListDialog({ predefinedCompanies: true });
  };

  return (
    <>
      <WorkflowDialogHeader title="How would you like to start?" />
      <div className="mx-auto w-1/3 flex flex-col gap-4 items-start flex-grow">
        <OptionButton
          title="I can describe what the companies do"
          subtitle="Give us a written brief and we’ll use AI to identify industries and keywords."
          onClick={onSemanticOptionClick}
          isBeta
          id="semantic-search-option"
        />
        <OptionButton
          title="I’ll start big and filter my way down"
          subtitle="Explore our database of millions of companies."
          onClick={() => push(ROUTES.CUSTOM_SEARCH)}
          id="simple-search-option"
        />
        <OptionButton
          title="I’ll upload companies"
          subtitle="I have a set of ID’s for specific companies."
          onClick={onUploadIdsOptionClick}
          id="upload-companies-option"
        />
      </div>

      <WorkflowDialogFooter
        onBackButtonClick={handleClose}
        isLoading={false}
        progress={progress}
      />
    </>
  );
};
