import { get, post } from 'aws-amplify/api';

import {
  ICompaniesSubsidiaries,
  INews,
  IMultiYear,
  IProfile,
  IMergesAndAcquisitions,
  IElasticTableData,
  ICompanySentiment,
} from '@/types';

export const getCompanyProfile = (bainId: string, currency: string): Promise<IProfile[]> =>
  post({ apiName: 'CDPAPI', path: `/company_profile?currency=${currency}`, options: { body: { bainIds: bainId } } })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((response) => response?.data);

export const getCompanySubsidiaries = (bainId: string, currency: string): Promise<ICompaniesSubsidiaries> =>
  get({ apiName: 'CDPAPI', path: `/company_profile/subsidiaries_elastic/${bainId}?currency=${currency}` })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((res) => res?.data);

export const getCompanyMultiyearFinancials = (bainId: string, currency: string): Promise<IMultiYear> =>
  get({ apiName: 'CDPAPI', path: `/company_profile/multiyear/${bainId}?currency=${currency}` })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((response) => response as IMultiYear);

export const getCompanyMergesAndAcquisitions = (bainId: string, currency: string): Promise<IMergesAndAcquisitions[]> =>
  get({ apiName: 'CDPAPI', path: `/company_profile/ma/${bainId}?currency=${currency}` })
    .response.then((response) => response.body.json())
    // @ts-ignore
    .then((res) => res.data);

export const getCompanyNews = (bainId: string, currency: string): Promise<INews[]> =>
  get({ apiName: 'CDPAPI', path: `/company_profile/news/${bainId}?currency=${currency}` })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((response) => response.data);

export const getCompanyProfileDatasets = (bainId: string, currency: string): Promise<string[]> =>
  get({ apiName: 'CDPAPI', path: `/company_profile2/${bainId}/_datasets?currency=${currency}` })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((response) => response?.datasets);

export const getCompanyProfileDataset = ({
  bainId,
  dataset,
  currency,
}: {
  bainId: string;
  dataset: string;
  currency: string;
}): Promise<IElasticTableData[]> =>
  get({ apiName: 'CDPAPI', path: `/company_profile2/${bainId}/${dataset}?currency=${currency}` })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((response) => response.data);

export const getCompanySentiment = (bainId: string, currency: string): Promise<ICompanySentiment> =>
  get({ apiName: 'CDPAPI', path: `/company_profile/glassdoor?bain_id=${bainId}&currency=${currency}` })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((response) => response as ICompanySentiment);
