import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { isNil, isEmpty } from 'lodash';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { useShallowSelector } from '../../../../../../hooks/use-shallow-selector';
import { actions } from '../../../../../../slices/filters';
import { NUMERIC_FILTER_TYPES } from '@/constants';
import { Switch } from '@/Components/Shared/Switch/Switch';

const MIN = 'min';
const MAX = 'max';

const COMMON_PROPS = {
  className: 'filter-numerical-input',
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
  InputProps: {
    classes: {
      root: 'text-black text-base',
    },
  },
  autoComplete: 'off',
};

const prepareValue = (value) => {
  if (value === '-') return '-';

  return isEmpty(value) ? '' : Number(value?.replace(/,/g, ''));
};

const getOtherMinMaxType = (type) => (type === MIN ? MAX : MIN);

function getInputsConfig(numericFilterType) {
  switch (numericFilterType) {
    case NUMERIC_FILTER_TYPES.YEARS:
      return { min: { affix: 'From ' }, max: { affix: 'To ' }, prefix: true };
    case NUMERIC_FILTER_TYPES.PERCENTAGE:
      return { min: { affix: '%' }, max: { affix: '%' }, prefix: false };
    case NUMERIC_FILTER_TYPES.EUR:
      return { min: { affix: '€' }, max: { affix: '€' }, prefix: true };
    case NUMERIC_FILTER_TYPES.USD:
      return { min: { affix: '$' }, max: { affix: '$' }, prefix: true }
    case NUMERIC_FILTER_TYPES.DATE:
      return { min: { affix: 'From' }, max: { affix: 'To' }, prefix: true };
    case NUMERIC_FILTER_TYPES.OTHER:
      return { min: { affix: 'Min ' }, max: { affix: 'Max ' }, prefix: true };
    default:
      return { min: { affix: 'Min ' }, max: { affix: 'Max ' }, prefix: true };
  }
}

const FilterNumericalInput = ({
  id,
  placeholder,
  type,
  value,
  handleChange,
  handleBlur,
  handleFocus,
  dataTestId,
  hasError,
}) => {
  const isDate = type === 'date';

  const formattedValue = useMemo(() => {
    if (isDate) return value;
    if (isNil(value)) return null;
    if (value === '-') return '-';

    const applyThousandSeparator = type !== 'YEARS';

    return applyThousandSeparator ? String(value).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : String(value);
  }, [value, type, isDate]);

  const InputComponent = isDate ? DatePicker : TextField;

  return (
    <InputComponent
      {...COMMON_PROPS}
      key={id}
      id={id}
      name={id}
      value={formattedValue}
      label={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      data-testid={dataTestId}
      error={hasError}
      {...(isDate ? { format: 'YYYY/MM/DD' } : { type: 'text' })}
    />
  );
};

export const FilterNumerical = ({ values: { hint_min, hint_max }, type, itemId, hideToggle }) => {
  const dispatch = useDispatch();
  const filterData = useShallowSelector((state) => state.filters.otherFilters[itemId]);
  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);
  // excluding null values can happen if and only if filter data is present (e.g. both min and max are null)
  // include null list is fallback for deprecated data schema
  const isChecked = filterData !== undefined && (filterData?.excludeNullValues || !includedNullList.includes(itemId));
  const currency = useShallowSelector((state) => state.config.currency);
  const updatedType = type === NUMERIC_FILTER_TYPES.CURRENCY ? currency : type;
  const inputConfig = getInputsConfig(updatedType);
  const [uiMin, setUiMin] = useState();
  const [uiMax, setUiMax] = useState();
  const [dateRange, setDateRange] = useState({
    min: filterData?.min ? dayjs(filterData?.min) : null,
    max: filterData?.max ? dayjs(filterData?.max) : null,
  });
  const isDate = type === 'date';

  useEffect(() => {
    if (isChecked) {
      dispatch(actions.removeIdFromNullList(itemId));
    } else {
      dispatch(actions.addIdToNullList(itemId));
    }
  }, [dispatch, isChecked, itemId]);

  const handleDateChange = (date, minMaxType) => {
    const range = {
      ...dateRange,
      [minMaxType]: date,
    };

    setDateRange(range);

    dispatch(
      actions.setFilter({
        id: itemId,
        data: {
          min: range.min?.isValid() ? range.min.format('YYYY-MM-DD') : null,
          max: range.max?.isValid() ? range.max.format('YYYY-MM-DD') : null,
        },
      }),
    );
  };

  const handleCommonChange = ({ target: { value } }, minMaxType) => {
    const formattedValue = prepareValue(value);
    const uiFormattedValue = value?.replace(/[^\d.-]/g, '');

    if (minMaxType === MIN) {
      setUiMin(uiFormattedValue);
    } else {
      setUiMax(uiFormattedValue);
    }

    const isFormattedValueNumber = typeof formattedValue === 'number';
    const otherMinMaxValue = filterData?.[getOtherMinMaxType(minMaxType)] ?? null;
    const isOtherMinMaxValueNumber = typeof otherMinMaxValue === 'number';

    if (isNil(formattedValue) || !isNaN(formattedValue) || formattedValue === '-') {
      dispatch(
        actions.setFilter({
          id: itemId,
          data: {
            [minMaxType]: isFormattedValueNumber ? formattedValue : null,
            [getOtherMinMaxType(minMaxType)]: isOtherMinMaxValueNumber ? otherMinMaxValue : null,
          },
        }),
      );
    }

    if (!isFormattedValueNumber && !isOtherMinMaxValueNumber && !isChecked) {
      dispatch(actions.removeFilter({ filter: itemId }));
    }

    if (!isFormattedValueNumber && !isOtherMinMaxValueNumber && isChecked) {
      dispatch(
        actions.setFilter({
          id: itemId,
          data: {
            min: null,
            max: null,
          },
        }),
      );
    }
  };

  const handleChange = (date, minMaxType) => {
    if (isDate) {
      return handleDateChange(date, minMaxType);
    }

    return handleCommonChange(date, minMaxType);
  };

  const handleChangeNull = ({ target: { checked } }) => {
    if (checked) {
      dispatch(actions.removeIdFromNullList(itemId));
    } else {
      dispatch(actions.addIdToNullList(itemId));
    }

    dispatch(
      actions.setFilter({
        id: itemId,
        data: {
          min: filterData?.min ?? null,
          max: filterData?.max ?? null,
        },
      }),
    );

    const isMinValueNumber = typeof filterData?.min === 'number';
    const isMaxValueNumber = typeof filterData?.max === 'number';

    if (!checked && !isMinValueNumber && !isMaxValueNumber) {
      dispatch(actions.removeFilter({ filter: itemId }));
    }
  };

  const minPlaceholder = useMemo(() => {
    if (!hint_min) {
      return inputConfig.min.affix;
    }

    return inputConfig.prefix ? `${inputConfig.min.affix}${hint_min}` : `${hint_min}${inputConfig.min.affix}`;
  }, [inputConfig.min.affix, inputConfig.prefix, hint_min]);

  const maxPlaceholder = useMemo(() => {
    if (!hint_max) {
      return inputConfig.max.affix;
    }

    return inputConfig.prefix ? `${inputConfig.max.affix}${hint_max}` : `${hint_max}${inputConfig.max.affix}`;
  }, [inputConfig.max.affix, inputConfig.prefix, hint_max]);

  const min = uiMin ?? filterData?.min;
  const max = uiMax ?? filterData?.max;

  const minHasError = min !== '' && !isNil(min) && (min === null || min === undefined || isNaN(min));
  const maxHasError = max !== '' && !isNil(max) && (max === null || max === undefined || isNaN(max));

  return (
    <>
      <div className="flex justify-between gap-4 mt-4 mb-2">
        <FilterNumericalInput
          id={`${itemId}-min`}
          placeholder={minPlaceholder}
          type={updatedType}
          value={isDate ? dateRange.min : min}
          handleChange={(event) => handleChange(event, MIN)}
          dataTestId={`${itemId}-min`}
          hasError={isDate ? false :minHasError}
        />
        <FilterNumericalInput
          id={`${itemId}-max`}
          placeholder={maxPlaceholder}
          type={updatedType}
          value={isDate ? dateRange.max : max}
          handleChange={(event) => handleChange(event, MAX)}
          dataTestId={`${itemId}-max`}
          hasError={isDate ? false : maxHasError}
        />
      </div>

      {hideToggle ? null : (
        <Switch
          id={`${itemId}-nullable`}
          label="Exclude missing values?"
          onToggle={handleChangeNull}
          isChecked={isChecked}
          dataTestId="column-visibility-toggle"
          labelPlacement="end"
        />
      )}
    </>
  );
};
