import React from 'react';
import { useSessionStorage } from 'usehooks-ts';
import cn from 'classnames';

import { useHistory } from 'react-router';
import { IconFindCompany, IconCreateList, IconCompareCompanies } from '@/Components/Icons';
import { HELIX_APPS, HIDE_SEMANTIC_SEARCH, LOCAL_STORAGE_KEYS, ROUTES } from '@/constants';
import { addCaseCodeQueryParamsToUrl } from '@/Utils/url-utils';
import { CASE_CODE_INITIAL_STATE, TCaseCodeData } from '@/hooks/queries/use-mutation-log-case-code';
import { useCaseCodePopupConditionally } from '@/Components/Dialogs/CaseCodeDialog/use-show-case-code-popup-conditionally';

export const HomeSearch = () => {
  const { push } = useHistory();
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);
  const { shouldShowCaseCodePopup, handleCaseCodeModalOpen } = useCaseCodePopupConditionally();

  const redirectToCompanyFinder = () => {
    push(ROUTES.COMPANY_FINDER);
  };

  const handleNewSearchClick = () => {
    if (HIDE_SEMANTIC_SEARCH) {
      window.open(ROUTES.CUSTOM_SEARCH, '_blank');

      return;
    }

    push(ROUTES.CREATE_LIST_WORKFLOW);
  };

  const redirectToCompareCompanies = (formData?: TCaseCodeData) => {
    if (!formData) return;
    window.open(addCaseCodeQueryParamsToUrl(HELIX_APPS.LEARN.link, formData), '_blank');
  };

  const onCompareCompaniesClick = () => {
    if (shouldShowCaseCodePopup) {
      handleCaseCodeModalOpen({ onSuccess: (formData?: TCaseCodeData) => redirectToCompareCompanies(formData) });

      return;
    }

    redirectToCompareCompanies(caseCodeData);
  };

  const LIST = [
    {
      id: 'FindCompany',
      label: 'Find a company',
      text: 'Search for a specific company by its name or URL.',
      className: 'hover:bg-[#00a3bf]',
      Icon: IconFindCompany,
      onClick: redirectToCompanyFinder,
    },
    {
      id: 'CreateList',
      label: 'Create a list',
      text: 'Build a list of companies based on your criteria.',
      className: 'hover:bg-[#1f995b]',
      Icon: IconCreateList,
      onClick: handleNewSearchClick,
    },
    {
      id: 'CompareCompanies',
      label: 'Compare companies',
      text: 'Review two or more companies side-by-side.',
      className: 'hover:bg-[#cc0100]',
      Icon: IconCompareCompanies,
      onClick: onCompareCompaniesClick,
    },
  ];

  return (
    <div className="flex items-center gap-4 h-[224px] bg-[#f8f9fa] px-6 py-8 relative">
      {LIST.map(({ id, label, text, className, Icon, onClick }) => (
        <button
          key={id}
          id={id}
          onClick={onClick}
          className={cn(
            'flex items-center gap-4 w-full max-w-[446px] bg-white shadow-[0_0_4px_0_rgba(0,0,0,0.25)] px-4 py-6 rounded transition cursor-pointer group',
            className,
          )}
        >
          <Icon />

          <div className="flex flex-col justify-center items-start gap-1">
            <p className="text-[#484848] group-hover:text-white font-medium">{label}</p>
            <p className="text-xs text-[#666] group-hover:text-white">{text}</p>
          </div>
        </button>
      ))}
    </div>
  );
};
