import React, { useEffect, useMemo } from 'react';

import { Button, CircularProgress, Skeleton } from '@mui/material';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useQueryTable } from '@/hooks/queries/table/use-query-table';
import { useFiltersHasChange } from '@/Components/Shared/FiltersDrawer/use-filters-has-change';
import { actions as searchActions } from '@/slices/search';
import { useTableLoading } from '@/hooks/table/use-table-loading';

export interface WizardDialogFooterProps {
  isLastStep: boolean;
  onPrimaryButtonClick?: () => void;
  primaryButtonId?: string;
  onSecondaryButtonClick?: () => void;
  onBackButtonClick: () => void;
  isLoading?: boolean;
  isOpen?: boolean;
  hideEstimated?: boolean;
  hideSkipButton?: boolean;
}

export const WizardDialogFooter = ({
  isLastStep,
  onPrimaryButtonClick,
  primaryButtonId,
  onSecondaryButtonClick,
  onBackButtonClick,
  isLoading,
  isOpen,
  hideEstimated,
  hideSkipButton,
}: WizardDialogFooterProps) => {
  const dispatch = useDispatch();
  const isTableLoading = useTableLoading();
  const { pageNumber } = useShallowSelector((state) => state.tableNotPersist);
  const query = useQueryTable();

  const totalCount = useMemo(() => query?.data?.pages[pageNumber]?.total_count, [pageNumber, query?.data?.pages]);

  const { currentHash } = useFiltersHasChange({});

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(searchActions.setIsElasticEnabled(true));
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHash]);

  const estimatedTotalCount = !isNil(totalCount) ? parseFloat(totalCount.toPrecision(2)) : undefined;

  const isEstimated = totalCount !== estimatedTotalCount;

  const primaryButtonDisabled = isLoading || !onPrimaryButtonClick;
  const secondaryButtonDisabled = isLoading || !onSecondaryButtonClick;

  return (
    <div className="sticky bottom-0 bg-white px-4 pb-4 z-40">
      <div className="h-12 flex items-center border-y border-[#F5F5F5] mb-4">
        {isTableLoading ? (
          <Skeleton
            width={180}
            height={28}
            className="bg-[#0000000f]"
          />
        ) : (
          isOpen &&
          !isNil(estimatedTotalCount) &&
          !hideEstimated && (
            <span className="text-sm font-normal">
              {isEstimated ? 'Estimated: ' : ''}
              <span className="font-medium">{`${estimatedTotalCount.toLocaleString('en')} companies`}</span>
            </span>
          )
        )}
      </div>
      <div className="flex justify-between items-center mt-2">
        <Button
          data-testid="wizard-back-button"
          variant="outlined"
          className="text-[#2e3f4c] border-none bg-[#fff] hover:bg-[#0000000a] h-10 py-2 text-sm font-medium flex items-center"
          onClick={onBackButtonClick}
          size="medium"
          disabled={isLoading}
          id={`${primaryButtonId}-wizard-back-button`}
        >
          <span className="self-baseline">Back</span>
        </Button>

        <div className="flex gap-4">
          {hideSkipButton ? null : (
            <Button
              variant="outlined"
              data-testid="wizard-skip-button"
              className={classnames(
                'text-[#2e3f4c] border-[#2e3f4c] bg-[#fff] hover:bg-[#0000000a] h-10 py-2 text-sm font-medium flex items-center',
                {
                  'opacity-50': secondaryButtonDisabled,
                },
              )}
              onClick={onSecondaryButtonClick}
              size="medium"
              disabled={secondaryButtonDisabled}
              id={`${primaryButtonId}-wizard-skip-button`}
            >
              <span className="self-baseline">Skip</span>
            </Button>
          )}

          <Button
            variant="outlined"
            data-testid="wizard-next-button"
            className={classnames(
              'text-[#fff] border-[#2e3f4c] bg-[#2e3f4c] hover:bg-[#2e3f4cdd] h-10 py-2 text-sm font-medium flex items-center',
              {
                'opacity-50': primaryButtonDisabled,
              },
            )}
            onClick={onPrimaryButtonClick}
            size="medium"
            disabled={primaryButtonDisabled}
            id={`${primaryButtonId}-wizard-${isLastStep ? 'done' : 'next'}-button`}
          >
            <span className="self-baseline">{isLastStep ? 'Done' : 'Next'}</span>
            {isLoading ? (
              <CircularProgress
                className="text-[#ddd] ml-3"
                size={20}
              />
            ) : null}
          </Button>
        </div>
      </div>
    </div>
  );
};
