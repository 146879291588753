import { isNil } from 'lodash';

export function pivotTable(
  rows: { [key: string]: unknown }[],
  pivotColumn: string,
  valueColumn: string,
  numericPrecision: number | null,
  aggregateFunction: (...values: number[]) => number = Math.max,
) {
  // for each row in table, collect object in form: {idempotent_row_key, {new_column: [...not_aggregated_values]}}
  const pivot = rows.reduce((result: { [key: string]: { [key: string]: number[] } }, current) => {
    const { [pivotColumn]: pivotColumnValueUnknown, [valueColumn]: valueColumnValueUnknown, ...rest } = current;
    const pivotColumnValue = pivotColumnValueUnknown as string;
    const valueColumnValue = valueColumnValueUnknown as number;
    const key = JSON.stringify(rest, Object.keys(rest).sort());

    if (!result || !(key in result)) {
      result[key] = {};
    }

    if (!(pivotColumnValue in result[key])) {
      result[key][pivotColumnValue] = [];
    }

    result[key][pivotColumnValue].push(
      isNil(numericPrecision) ? valueColumnValue : Number(valueColumnValue.toFixed(numericPrecision)),
    );

    return result;
  }, {});

  // for each row, collect object in form: {idempotent_row_key, {new_column: aggregated_value}}
  const aggregatedPivot = Object.fromEntries(
    Object.entries(pivot).map(([baseRow, pivoted]) => [
      baseRow,
      Object.fromEntries(
        Object.entries(pivoted).map(([pivotColumnValue, pivotValues]) => [
          pivotColumnValue,
          aggregateFunction(...pivotValues),
        ]),
      ),
    ]),
  );

  // convert row key back from string to object add fields for new columns
  const final = Object.entries(aggregatedPivot).map(([baseRow, pivotedValues]) => ({
    ...JSON.parse(baseRow),
    ...pivotedValues,
  }));

  return final;
}
