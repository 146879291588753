import React from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import cn from 'classnames';

import { useMenu } from '@/hooks/use-menu';
import { SimpleSearch } from '@/Components/Shared/SimpleSearch';

interface IProps {
  disabled?: boolean;
  chartId: number;
  selectCallback?: () => void;
}

export const CompareButton = ({ disabled = false, chartId, selectCallback }: IProps) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  const onSelectCallback = () => {
    handleClose();

    if (selectCallback) {
      selectCallback();
    }
  };

  return (
    <div className="relative">
      <Button
        disabled={disabled}
        variant="outlined"
        onClick={handleClick}
        className={cn(
          'text-xs text-[#2e3f4c] border-[#2e3f4c] py-1.5 px-2',
          {
            'opacity-50': disabled,
          },
        )}
        disableRipple
      >
        Compare
      </Button>

      <Popover
        id="searchid"
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: 'overflow-visible',
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 18, horizontal: 340 }}
      >
        <SimpleSearch type="compare" className="w-[420px]" metadata={{ chartId }} selectCallback={onSelectCallback} />
      </Popover>
    </div>
  );
};
