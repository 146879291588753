import React from 'react';

export const ThirdIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 20 20">
      <path fill="#fff" d="M6.25 13.777h1.989v-3.622h3.522v3.622h1.989V5h-1.989v3.584H8.24V5H6.25v8.777Z"/>
      <path fill="#fff" d="M20 10c0 5.54-4.46 10-10 10S0 15.54 0 10 4.46 0 10 0c.575 0 1.08.072 1.583.144l-.864 1.079H10c-4.676 0-8.417 3.67-8.417 8.201 0 4.533 3.74 8.202 8.417 8.202 4.676 0 8.417-3.67 8.417-8.202 0-3.525-2.23-6.474-5.395-7.697L12.734.36C16.978 1.655 20 5.468 20 10Z"/>
    </svg>
  );
};
