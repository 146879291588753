import React from 'react';
import { useHistory } from 'react-router';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import cn from 'classnames';
import { useSessionStorage } from 'usehooks-ts';

import { AppDropdownItem } from './AppDropdownItem';
import { AppDropdownItemType } from './types';
import { HELIX_OFFERINGS, HELIX_TOOLS } from './constants';
import { LOCAL_STORAGE_KEYS, HOME_PAGE_SLUG } from '@/constants';
import { useMenu } from '@/hooks/use-menu';
import { IconBrowser } from '@/Components/Icons/IconBrowser';
import { CASE_CODE_INITIAL_STATE } from '@/hooks/queries/use-mutation-log-case-code';
import { addCaseCodeQueryParamsToUrl } from '@/Utils/url-utils';
import { IconChevronDown } from '@/Components/Icons/IconChevronDown';

export const HelixAppsDropdown = () => {
  const history = useHistory();
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);

  const onClick = (item: AppDropdownItemType) => {
    item.label === 'Find'
      ? history.push(HOME_PAGE_SLUG)
      : window.open(addCaseCodeQueryParamsToUrl(item.url, caseCodeData), '_blank')
  };

  return (
    <div className="relative">
      <Button
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        aria-controls={isOpen ? 'helix-apps-menu' : undefined}
        disableElevation
        onClick={handleClick}
        data-testid="helix-apps-dropdown"
        classes={{ root: 'p-1 grid grid-cols-3' }}
        disableRipple
      >
        <IconBrowser className="ml-1 mr-2" />

        <span className="text-[#2e3f4c] font-normal text-sm cursor-pointer">Find</span>

        <IconChevronDown
          className={cn('ml-1 transition', { 'rotate-180': isOpen })}
          fill="#2E3F4C"
        />
      </Button>

      <Popover
        id="helix-app-list"
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: 'flex flex-col gap-6 shadow-[0_2px_6px_0px_rgba(0,0,0,0.3)] border border-solid border-[#ddd] py-6 px-4 rounded-lg w-[650px]',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <AppDropdownItem
          title="Helix tools"
          list={HELIX_TOOLS}
          onClick={onClick}
        />
        <AppDropdownItem
          title="Helix Specialist offerings"
          list={HELIX_OFFERINGS}
          onClick={onClick}
        />
      </Popover>
    </div>
  );
};
