import { omit } from 'lodash';
import { checkIfStringStartsWith } from './text';
import { TCaseCodeData } from '@/hooks/queries/use-mutation-log-case-code';

const UUID_LENGTH = 36;

export const getUuidFromStringEnd = (text: string) => text.slice(-UUID_LENGTH);

export const addCaseCodeQueryParamsToUrl = (url: string, caseCodeFormData: TCaseCodeData) => {
  const { formData, ...rest } = caseCodeFormData;
  const flatCaseCodeFormData = { ...formData, ...rest };

  const queryParams = new URLSearchParams(omit(flatCaseCodeFormData, ['onSuccess', 'shouldBeLogged'])).toString();

  const urlWithoutLastSlash = url.endsWith('/') ? url.slice(0, -1) : url;

  const alreadyHasQueryParams = urlWithoutLastSlash.includes('?');

  return `${urlWithoutLastSlash}${alreadyHasQueryParams ? '&' : '?'}${queryParams}`;
};

export const isUrl = (string: string) =>
  checkIfStringStartsWith(string, ['http://', 'https://', 'www.']) && !/\s/g.test(string.trim());
