import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions, QueryType } from '@/slices/search';
import CompanyFinder from '@/Components/CompanyResearch/CompanyFinder';
import { useScreener } from '@/hooks/use-screen';

const CompanyFinderPage = () => {
  const dispatch = useDispatch();
  const { softReset } = useScreener();

  useEffect(() => {
    dispatch(actions.setQueryType(QueryType.MATCH));

    return () => {
      softReset();
    };
  }, [dispatch, softReset]);

  return <CompanyFinder />;
};

export default CompanyFinderPage;
