import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import mainLogo from '../../../assets/helix_find_logo.svg';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const WelcomeDialog = () => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);

  const [isFirstLogin, setIsFirstLogin] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.WELCOME_TO_HELIX,
    true,
  );

  const handleClick = () => {
    setIsFirstLogin(false);
  };

  return isAuth ? (
    <Dialog
      open={isFirstLogin}
      onClose={handleClick}
      classes={{ paper: 'w-[620px] rounded-lg px-6 py-6 pb-16' }}

    >
      <DialogTitle className='flex justify-between p-0'>
        <img
          src={mainLogo}
          style={{ width: 24, height: 24 }}
          alt="logo"
        />

        <IconButton
          aria-label="expand row"
          size="small"
          onClick={handleClick}
        >
          <CloseIcon
            fontSize="small"
            className="cursor-pointer fill-[#484848]"
            id="welcome-dialog-started-button"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className='px-32 pt-4 text-center'>
        <p className='text-base font-medium text-[#666666]'>Welcome to</p>
        <p className='text-7xl font-semibold text-[#484848] pt-4'>Helix</p>
        <p className='text-sm text-[#666666] pt-5'>A state of the art company data platform.</p>
        <p className='text-sm text-[#666666] pt-4'>Helix is a data platform that aggregates data on millions of companies across mulitple sources.</p>
      </DialogContent>

      <DialogActions className='px-32 m-auto'>
        <Button
          id="welcome-dialog-started-button"
          variant="contained"
          className=" bg-[#2e3f4c] font-medium text-sm py-2.5"
          onClick={handleClick}
        >
          Get started
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
