import { fromPairs, groupBy, get, map, partition, toPairs } from 'lodash';

export const pluck = (path: string, data: unknown[]) => data.map((item) => get(item, path));

export function groupAndSortList<T>(list: T[] = [], orderList: string[] = [], key = 'id'): [string, T[]][] {
  const sectionsList = Object.entries(groupBy(list, key));

  sectionsList?.sort((a, b) => orderList.indexOf(a[0]) - orderList.indexOf(b[0]));

  return sectionsList;
}

export const getIdByLabel = (label: string) => label.replace(/-| /g, '');

export function splitObject<T extends object>(object: T, predicate: (value: unknown) => void) {
  const [trueGroup, falseGroup] = partition(toPairs(object), ([, value]) => predicate(value));

  const objectPositive = fromPairs(trueGroup);
  const objectNegative = fromPairs(falseGroup);

  return { objectPositive, objectNegative };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function splitObjectToSingleFieldObjects(object: Record<string, any>) {
  return map(toPairs(object), ([key, value]) => ({ [key]: value }));
}

export const getPrettyLink = (url: string): string => {
  let link = url.replace(/^(https?:\/\/)?(www\.)?/, '');

  link = link.split('/')[0];
  link = link.split('?')[0];
  link = link.split(':')[0];

  if (link.startsWith('www.')) {
    link = link.slice(4);
  }

  return link;
}

export const ensureFullUrl = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }

  return url;
};
