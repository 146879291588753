import React from 'react';

import { useSnackbar } from 'notistack';

import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';

export const SEMANTIC_DESCRIPTION_MIN_CHARACTERS = 50;
const MAX_CHARACTERS = 2000;

export const useSemanticSearchValueValidation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const validate = (value?: string, skipToast?: boolean) => {
    const toastErrorMessage = `Oops. Please provide at least ${SEMANTIC_DESCRIPTION_MIN_CHARACTERS} characters and focus on what the company does, avoiding excessive negatives. This will help us deliver more accurate results. Try again`;
    const inlineErrorMessage = `Provide at least ${SEMANTIC_DESCRIPTION_MIN_CHARACTERS} characters. Focus on what the company does, avoiding excessive negatives. This will deliver more accurate results.`;
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      if (!skipToast) {
        enqueueSnackbar(<NotificationMessage title={toastErrorMessage} />, { variant: 'error' });
      }

      return { validated: false, errorMessage: inlineErrorMessage };
    }

    const numberOfCharacters = trimmedValue.length;

    if (numberOfCharacters < SEMANTIC_DESCRIPTION_MIN_CHARACTERS) {
      if (!skipToast) {
        enqueueSnackbar(<NotificationMessage title={toastErrorMessage} />, { variant: 'error' });
      }

      return { validated: false, errorMessage: inlineErrorMessage };
    }

    if (numberOfCharacters > MAX_CHARACTERS) {
      if (!skipToast) {
        enqueueSnackbar(
          <NotificationMessage
            title={`Oops. Please provide maximum ${MAX_CHARACTERS} characters and focus on what the company does, avoiding excessive negatives. This will help us deliver more accurate results. Try again`}
          />,
          { variant: 'error' },
        );
      }

      return {
        validated: false,
        errorMessage: `Provide maximum ${MAX_CHARACTERS} characters. Focus on what the company does, avoiding excessive negatives. This will deliver more accurate results.`,
      };
    }

    return {
      validated: true,
      errorMessage: '',
    };
  };

  return { validate };
};
