// const CORE_COLORS = [
//   '#CC0000',
//   '#0484E7',
//   '#484848',
//   '#666666',
//   '#DDDDDD',
//   '#FFFFFF',
//   '#F5F5F5',
//   '#000000',
//   '#21A663',
//   '#F59B00',
//   '#D63333',
// ];

export const CHART_PALETTE = {
  // core: CORE_COLORS,
  grey: ['#2E3F4C', '#2F5574', '#506D85', '#738A9D', '#96A7B6', '#B9C5CE'],
  red: ['#8A0F0F', '#CC0000', '#D63333', '#E16666', '#EB9999', '#F5CDCC'],
  yellow: ['#CA6602', '#F59B00', '#FFCD00', '#FFDB48', '#FFE785', '#FEEC9A'],
  blue: ['#023F6E', '#0361AA', '#0484E7', '#229DFB', '#78C3FD', '#CDE9FE'],
  green: ['#0C5F35', '#1E995B', '#21A663', '#4FC489', '#7EC8A3', '#C9EEDB'],
  cyan: ['#008DA6', '#00A3BF', '#00B8D9', '#00C7E6', '#79E2F2', '#B3F5FF'],
  // neutral: ['#000000', '#484848', '#666666', '#999999', '#DDDDDD', '#FAFAFA'],
  violet: ['#403294', '#5243AA', '#6554C0', '#8777D9', '#998DD9', '#C0B6F2'],
  purple: ['#550736', '#710948', '#890C58', '#890C58', '#B86C9B', '#D19FBD'],
  pink: ['#6E022A', '#CE034E', '#F2075E', '#FD2B78', '#FD78A9', '#FECDDF'],
};

const flattenedPalette = Object.values(CHART_PALETTE).flat();

export const getChartColor = (index: number): string => {
  return flattenedPalette[index % flattenedPalette.length];
};

export const getChartColors = (count: number) => {
  const allColors = Object.values(CHART_PALETTE).flat();
  const step = Math.floor(allColors.length / count);

  return Array.from({ length: count }, (_, i) => allColors[(i * step) % allColors.length]);
};

export const interpolateHexColor = (colorCode1: string, colorCode2: string, progress: number) => {
  const colorCodes1 = colorCode1
    .substring(1)
    .match(/.{1,2}/g)
    ?.map((oct) => parseInt(oct, 16) * (1 - progress));
  const colorCodes2 = colorCode2
    .substring(1)
    .match(/.{1,2}/g)
    ?.map((oct) => parseInt(oct, 16) * progress);

  if (!colorCodes1 || !colorCodes2) return '';

  const ci = [0, 1, 2].map((i) => Math.min(Math.round(colorCodes1?.[i] + colorCodes2?.[i]), 255));

  return (
    '#' +
    ci
      .reduce((a, v) => (a << 8) + v, 0)
      .toString(16)
      .padStart(6, '0')
  );
};

export const COMPARISON_MAIN_PALETTE = ['#677F92', '#E16666', '#FFDB48', '#4FC489', '#B86C9B', '#00B8D9'];
export const COMPARISON_SECONDARY_PALETTE = ['#95a7b6', '#ec9999', '#ec9999', '#7ec8a3', '#b86c9b', '#78e2f2'];
