import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, ChartDataset, ChartOptions, Plugin } from 'chart.js';
import Divider from '@mui/material/Divider';
import _ from 'lodash';
import 'chart.js/auto';

import { DownloadChartImageButton } from '../common/DownloadChartImageButton';
import { COMPARISON_MAIN_PALETTE } from '../common/colors';
import { DownloadCsvButton } from '../../Buttons/DownloadCsvButton';
import { CompareButton } from '../common/CompareButton';
import { CustomLegend } from '../common/CustomLegend';
import { getUniqueLabels, groupLegendData } from '../common/utils';
import { ChartConfigType, ChartDatasetType, ChartLegendItem, Currency, DatumDatum } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { formatNumericChartValue } from '@/Utils/charts';
import { CHART_COMPARE_LIMIT, CHART_SECTIONS } from '@/constants';
import { CompareChartsType } from '@/types/state/charts';
import { useQueriesChartCompany } from '@/hooks/queries';

interface IProps {
  config: ChartConfigType;
  params?: { row: DatumDatum };
  section: string;
  data: ChartDatasetType;
  comparisonDatasets: ChartDatasetType[];
  handleDownloadXlsx: () => void;
  handleDownloadImage: (ref: React.RefObject<Chart>) => void;
  handleRemoveDataset: (data: CompareChartsType) => void;
}

const getOptions = (
  currency: Currency,
  config: ChartConfigType,
  companies: Record<string, string>,
): ChartOptions<'line'> => {
  const aggregateBy = config.METADATA?.aggregate_by;

  return {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: { display: false },
      title: {
        display: true,
        text: '',
      },
      tooltip: {
        enabled: true,
        position: 'nearest',
        yAlign: 'top',
        xAlign: 'center',
        caretSize: 8,
        caretPadding: 18,
        itemSort: (a, b) => Number(b.raw) - Number(a.raw),
        filter: (data) => !_.isNil(data.raw),
        ...(!aggregateBy && {
          displayColors: true,
          callbacks: {
            label: function (context) {
              if (context.parsed.y !== null) {
                const { label } = context.dataset;

                if (label) {
                  const splited = label.split(':');
                  const company = companies[splited[0]];

                  return `${company}: ${formatNumericChartValue({
                    value: context.parsed.y,
                    yAxisType: config.YAXIS_FORMAT,
                    currency,
                  })}`;
                }
              }
            },
          },
        }),
      },
      // @ts-ignore
      customCanvasBackgroundColor: {
        color: 'white',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: '#ddd',
        },
        border: {
          dash: [2, 2],
        },
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return formatNumericChartValue({
              value,
              yAxisType: config.YAXIS_FORMAT,
              currency,
            });
          },
        },
      },
    },
  };
};

const plugin: Plugin<'line'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, __, opts) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = opts.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const getDatasets = (
  labels: (string | number)[],
  data: ChartDatasetType,
  comparisonDatasets: ChartDatasetType[],
  config: ChartConfigType,
) => {
  const aggregateBy = config.METADATA?.aggregate_by;

  const createDataset = (dataset: ChartDatasetType, color: string) => {
    if (aggregateBy) {
      return _.map(_.groupBy(dataset.data, aggregateBy), (items, label) => {
        const dataPoints = labels.map((date) => {
          const item = items.find((it) => it[config.XAXIS] === date);

          return item ? item[config.YAXIS] : null;
        });

        return {
          label,
          data: dataPoints,
          pointRadius: 0,
          spanGaps: true,
          pointHoverRadius: 6,
        };
      });
    }

    const dataMap = new Map(dataset.data.map((item) => [item[config.XAXIS], item[config.YAXIS]]));

    return [
      {
        label: `${dataset.bainId}:${config.TITLE}`,
        data: labels.map((label) => dataMap.get(label) || null),
        borderColor: color,
        pointBackgroundColor: color,
        pointBorderColor: color,
        pointRadius: 0,
        pointHoverRadius: 6,
        spanGaps: true,
        pointHoverBackgroundColor: '#0484e7',
        pointHoverBorderColor: '#fff',
      },
    ];
  };

  const mainDataset = createDataset(data, COMPARISON_MAIN_PALETTE[0]);
  const compareDataset = comparisonDatasets.flatMap((dataset, index) =>
    createDataset(dataset, COMPARISON_MAIN_PALETTE[index + 1]),
  );

  return [...mainDataset, ...compareDataset];
};

export const LineChart = ({
  config,
  data,
  comparisonDatasets,
  section,
  handleDownloadXlsx,
  handleDownloadImage,
  handleRemoveDataset,
}: IProps) => {
  const { data: companies } = useQueriesChartCompany(data.bainId);
  const currency = useShallowSelector((state) => state.config.currency);
  const ref = useRef<Chart<'line'>>(null);
  const options = useMemo(() => getOptions(currency, config, companies), [companies, config, currency]);
  const labels = useMemo(() => getUniqueLabels(data, comparisonDatasets, config), [comparisonDatasets, config, data]);
  const datasets = useMemo(
    () => getDatasets(labels, data, comparisonDatasets, config),
    [config, data, comparisonDatasets, labels],
  );
  const chartData = useMemo(() => ({ labels, datasets }), [labels, datasets]);
  const isCompareDisabled = comparisonDatasets.length >= CHART_COMPARE_LIMIT;
  const [legends, setLegends] = useState<Map<string, ChartLegendItem[]>>(new Map());
  const aggregateBy = config.METADATA?.aggregate_by;

  useEffect(() => {
    const groupedLegends = groupLegendData(chartData.datasets as ChartDataset[]);

    setLegends(groupedLegends);
  }, [chartData]);

  return (
    <div
      className="bg-white rounded"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      <div className="flex items-center justify-between px-6 py-3">
        <div className="flex items-center gap-4">
          <span
            className="text-[#484848] text-sm font-semibold"
            data-testid="chart-title"
          >
            {config.TITLE}
          </span>
          {/*
          This is tactical fix for 2024-06 refresh. We must come up with generic solution.
          One idea would be to have three separate column types:
          - values (xaxis, yaxis)
          - statics (column with single distinct value across all rows)
          - filters (column with more than one distinct value across all rows).
          Then "Data Source" can be generic filter without need to special implementation.
          */}
          {section === CHART_SECTIONS.WEB_TRAFFIC && data.data?.[0]?.DOMAIN && (
            <>
              <Divider
                className="text-[#ddd] h-[16px]"
                orientation="vertical"
                variant="middle"
              />

              <div className="flex items-center gap-2">
                <span className="text-sm text-[#484848]">Showing web traffic metrics for</span>

                <a
                  href={`${'https://' + data.data?.[0]?.DOMAIN}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#0484e7] text-sm underline"
                >
                  {data.data?.[0]?.DOMAIN}
                </a>
              </div>
            </>
          )}
        </div>

        <div className="flex items-center gap-4">
          {section === CHART_SECTIONS.WEB_TRAFFIC && <span className="text-sm text-[#666]">Source: SimilarWeb</span>}

          {!aggregateBy && (
            <CompareButton
              chartId={config.ID}
              disabled={isCompareDisabled}
            />
          )}

          <DownloadChartImageButton
            onClick={() => handleDownloadImage(ref)}
            dataTestId="chart-download-image"
            id={`${config.SLUG}-chart-download-image`}
          />

          <DownloadCsvButton
            onClick={handleDownloadXlsx}
            dataTestId="chart-download-xlsx"
            id={`${config.SLUG}-chart-download-xlsx`}
          />
        </div>
      </div>

      <hr className="text-[#ddd]" />

      <div className="h-[400px] px-6 pb-4">
        <Line
          // @ts-ignore
          ref={ref}
          data={chartData}
          options={options}
          plugins={[plugin]}
        />
      </div>

      {!aggregateBy && (
        <div className="px-6 py-2">
          <CustomLegend
            bainId={data.bainId}
            chartId={config.ID}
            groupedLegends={legends}
            onRemoveDataset={handleRemoveDataset}
          />
        </div>
      )}
    </div>
  );
};
