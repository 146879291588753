type TStarterPackWizardStepFilterDefaultValueCategorical = (string | null)[];
type TStarterPackWizardStepFilterDefaultValueTree = { [key: string]: string | null }[];
type TStarterPackWizardStepFilterDefaultValueNumerical = {
  min: number;
  max: number;
  excludeNulls: boolean;
};

export type TStarterPackWizardStepFilterDefaultValue =
  | TStarterPackWizardStepFilterDefaultValueCategorical
  | TStarterPackWizardStepFilterDefaultValueNumerical
  | TStarterPackWizardStepFilterDefaultValueTree;

export const STARTER_PACK_WIZARD_STEP_ITEM_TYPE = {
  FILTER: 'filter',
  CHOICE: 'choice',
  TITLE: 'title',
  DESCRIPTION: 'description',
} as const;

// Discriminated Union
export type StarterPackWizardStepItem = {
  type: typeof STARTER_PACK_WIZARD_STEP_ITEM_TYPE[keyof typeof STARTER_PACK_WIZARD_STEP_ITEM_TYPE];
  condition?: { id: string; op: 'eq' | 'ne'; value: string };
  collapsible_section_title?: string;
} & (
  | StarterPackWizardStepItemFilter
  | StarterPackWizardStepItemChoice
  | StarterPackWizardStepItemTitle
  | StarterPackWizardStepItemDescription
);

export type StarterPackWizardStepItemFilter = {
  type: typeof STARTER_PACK_WIZARD_STEP_ITEM_TYPE.FILTER;
  filter: string;
  default_values: TStarterPackWizardStepFilterDefaultValue;
  tooltip?: string;
  placeholders?: {
    max: string;
    min: string;
  };
};

export type StarterPackWizardStepItemChoice = {
  type: typeof STARTER_PACK_WIZARD_STEP_ITEM_TYPE.CHOICE;
  id: string;
  question: string;
  options: { option: string; description?: string }[];
};

export type StarterPackWizardStepItemTitle = {
  type: typeof STARTER_PACK_WIZARD_STEP_ITEM_TYPE.TITLE;
  title: string;
};

export type StarterPackWizardStepItemDescription = {
  type: typeof STARTER_PACK_WIZARD_STEP_ITEM_TYPE.DESCRIPTION;
  description: string;
};

export type StarterPackWizardStep = {
  title: string;
  description?: string;
  items: StarterPackWizardStepItem[];
  hide_skip_button?: boolean;
};

export interface IStarterPackWizard {
  title: string;
  custom_agg: string;
  steps: StarterPackWizardStep[];
  extra_visible_columns?: string[];
  wizard_id: string;
  slug: string;
  hide_stepper?: boolean;
}

export type StarterPackWizardChoices = { [key: string]: string | undefined };
