import React, { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import CompanyProfileView from '@/Components/CompanyResearch/CompanyProfile/CompanyProfileView';
import { Tab } from '@/Components/CompanyResearch/CompanyProfile/types';
import { ROUTES } from '@/constants';
import { generateTab } from '@/Components/CompanyResearch/CompanyProfile/tabs/CompanyProfileTabs';
import { useQueryConfig } from '@/hooks/queries/config/use-query-config';
import { useScreener } from '@/hooks/use-screen';

export const createCompanyProfileLink = (bainId: string, tab: Tab | null = null) =>
  `${ROUTES.COMPANY_PROFILE}/${bainId}/${tab ? tab.slug : ''}`;

const CompanyProfile: FC = () => {
  const companyProfileConfig = useQueryConfig<{ tabs: Tab[] }>('company_profile');
  const { bainId, tab } = useParams<{ bainId: string; tab?: string }>();
  const { push } = useHistory();
  const { softReset } = useScreener();
  const [currentTab, setCurrentTab] = useState<Tab>();
  const defaultTab = companyProfileConfig.data?.tabs[0];

  useEffect(() => {
    if (!defaultTab) {
      // still loading
      return;
    }

    if (tab) {
      setCurrentTab(generateTab(tab));
    }

    if (!tab) {
      setCurrentTab(defaultTab);
      push(createCompanyProfileLink(bainId, defaultTab));
    }
  }, [bainId, push, tab, defaultTab]);

  useEffect(() => {
    return () => {
      softReset();
    };
  }, [softReset]);

  if (companyProfileConfig.isLoading) {
    return null;
  }

  return (
    <CompanyProfileView
      bainId={bainId}
      currentTab={currentTab ?? (defaultTab as Tab)}
    />
  );
};

export default CompanyProfile;
