import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getCompanySentiment } from '@/services/api/company-profile';

export const useQueryCompanySentiment = (bainId: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.config.currency);
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(['company-sentiment', bainId, currency], () => getCompanySentiment(bainId, currency), {
    enabled: isAuth && !!bainId,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
    onError: () =>
      enqueueSnackbar('Failed to fetch company sentiment data. Please try again or contact support.', {
        variant: 'error',
      }),
  });
};
