import React from 'react';
import Button from '@mui/material/Button';

export const COLUMNS_VISIBILITY_PANEL_BUTTON_ID = 'toggle-columns-visibility-panel-button';

export const ShowColumnsVisibilityPanelButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    className="ml-2.5 px-2 py-2 border-bluegray-900 text-bluegray-900 border border-solid hover:bg-bluegray-900 hover:text-white font-graphik text-xs h-[36px]"
    onClick={onClick}
    data-testid={COLUMNS_VISIBILITY_PANEL_BUTTON_ID}
    id={COLUMNS_VISIBILITY_PANEL_BUTTON_ID}
  >
    Columns
  </Button>
);
