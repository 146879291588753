import { Button } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

import { useDispatch } from 'react-redux';
import { isNull } from 'lodash';
import { actions as searchActions } from '../../../../slices/search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { MODALS } from '@/constants';
import { ConfirmDialogProps } from '@/Components/Dialogs/ConfirmDialog';
import { useSemanticSearchValueValidation } from '@/Components/Workflows/CreateListWorkflow/steps/useSemanticSearchValueValidation';

export const SemanticAgainButton = ({ newDescription }: { newDescription: string }) => {
  const dispatch = useDispatch();

  const { handleOpen: handleConfirmModalOpen, handleClose: handleConfirmModalClose } = useModal<ConfirmDialogProps>(
    MODALS.CONFIRM_DIALOG,
  );
  const { handleOpen: openLoadingModal } = useModal(MODALS.SEMANTIC_SEARCH_LOADING_DIALOG);
  const { validate } = useSemanticSearchValueValidation();

  const { semanticSearch: semanticSearchState } = useShallowSelector((state) => state.search);
  const descriptionChecked = semanticSearchState?.applyDescription;

  const onConfirm = () => {
    dispatch(searchActions.setSemanticSearch({ userDescription: newDescription, applyDescription: true }));
    handleConfirmModalClose();
    openLoadingModal();
  };

  const onButtonClick = () => {
    if (!validate(newDescription).validated || !newDescription) return;

    handleConfirmModalOpen({
      title: 'Are you sure?',
      subtitle: 'You will lose all current search data',
      primaryButtonLabel: 'Yes',
      secondaryButtonLabel: 'No',
      onPrimaryButtonClick: onConfirm,
      onSecondaryButtonClick: handleConfirmModalClose,
    });
  };

  const isSemanticNull = isNull(semanticSearchState);

  const disabled = isSemanticNull ? false : !descriptionChecked;

  return (
    <Button
      variant="outlined"
      className={classnames(
        'text-[#fff] border-[#2e3f4c] bg-[#2e3f4c] hover:bg-[#2e3f4cdd] py-1 h-8 text-sm font-medium flex items-center absolute bottom-3 left-3',
        {
          'opacity-50': disabled,
        },
      )}
      onClick={onButtonClick}
      size="small"
      disabled={disabled}
    >
      <span className="self-baseline">Search again</span>
    </Button>
  );
};
