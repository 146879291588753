import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

import { isArray, trim } from 'lodash';
import { useQueryFilterTree } from '../../../../hooks/queries/use-query-filter-tree';
import { pluck } from '../../../../Utils';
import { useCategoryAppliedFilters } from '../../../../hooks/filters/use-category-applied-filters';
import { FilterItem } from './FilterItem';
import { FilterDrawerAccordion } from './FilterDrawerAccordion';
import { FiltersAvailableData } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { QueryType } from '@/slices/search';
import { IFilterTreeData } from '@/types/state/filter-tree';
import { IFilterCategoricalData } from '@/types/state/filter-categorical';
import { HIDE_FILTERS_COUNT } from '@/constants';
import { overrideFiltersCounts } from '@/Utils/filters';

interface IFilterHeader {
  filter: FiltersAvailableData;
  searchValue: string;
  blockStatsCall: boolean;
  withoutCounts: boolean;
}

export const FilterHeader = ({
  filter: { displayHeader, items: availableCategoryFilters },
  searchValue,
  blockStatsCall,
  withoutCounts,
}: IFilterHeader) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterIds = pluck('backendName', availableCategoryFilters);
  const { searchQuery, queryType, searchText } = useShallowSelector((state) => state.search);
  const isKeywordQuery = queryType === QueryType.KEYWORD;
  const isSmartQuery = queryType === QueryType.SMART;
  const is_lucene = isKeywordQuery || isSmartQuery;
  const company_search = is_lucene ? trim(searchQuery) : trim(searchText);
  const shouldOverrideDefaultFilterCounts = Boolean(company_search);

  const { data, isFetching } = useQueryFilterTree({
    ids: filterIds,
    enabled:
      !!filterIds.length && shouldOverrideDefaultFilterCounts && isOpen && !HIDE_FILTERS_COUNT && !blockStatsCall,
  });

  const { numberOfCategoryAppliedFilters, formattedCategoryAppliedFilters, handleRemoveCategoryFilters } =
    useCategoryAppliedFilters({
      availableCategoryFilters,
    });

  useEffect(() => {
    setIsOpen(!!searchValue);
  }, [searchValue]);

  return (
    <div data-testid="filter-header">
      <FilterDrawerAccordion
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        displayHeader={displayHeader}
        accordionSummaryContent={
          <span>
            {displayHeader}
            {numberOfCategoryAppliedFilters ? (
              <Tooltip title={formattedCategoryAppliedFilters}>
                <span className="bg-[#ECF6FD] text-[#484848] rounded border-[#0288d1] border-[1px] text-xs px-1 ml-4 font-medium inline-flex items-center h-full">
                  {`${numberOfCategoryAppliedFilters} active`}{' '}
                  <CloseIcon
                    onClick={handleRemoveCategoryFilters}
                    className="fill-[#666] text-[18px] ml-1"
                  />
                </span>
              </Tooltip>
            ) : null}
          </span>
        }
      >
        {availableCategoryFilters.map((filter) => {
          const filterItems = () => {
            if (
              shouldOverrideDefaultFilterCounts &&
              filter.__type__ !== 'numerical' &&
              isArray(filter.filterItems) &&
              data
            ) {
              const updatedCountsData = data?.[filter.backendName] ?? [];

              return filter.filterItems?.map((filterItem) =>
                overrideFiltersCounts(
                  filterItem,
                  updatedCountsData as unknown as IFilterTreeData | IFilterCategoricalData,
                ),
              );
            }

            return filter.filterItems;
          };

          return (
            <FilterItem
              key={filter.backendName}
              isFetching={isFetching}
              itemId={filter.backendName}
              itemName={filter.displayName}
              itemType={filter.__type__}
              itemUnit={filter.unit}
              filterItems={filterItems()}
              categoryFilterId={displayHeader}
              searchValue={searchValue}
              withoutCounts={withoutCounts}
            />
          );
        })}
      </FilterDrawerAccordion>
    </div>
  );
};
