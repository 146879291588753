import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Tooltip } from '@mui/material';
import { without } from 'lodash';
import { FilterChip } from './FilterChip';

import { ELEMENT_ROLES, IAggregationElement, useFiltersToDisplay } from './use-filters-to-display';
import { actions as filtersActions } from '@/slices/filters';
import { actions as searchActions } from '@/slices/search';
import { useModal } from '@/hooks/use-modal';
import { FILTER_TYPES, MODALS } from '@/constants';

const FILTERS_TO_AGGREGATE = ['self_location_region___', 'self_sector_sector___'];

export const SavedFilters = () => {
  const dispatch = useDispatch();
  const { showSavedFilters, filtersToDisplay } = useFiltersToDisplay();
  const { handleOpen: openAdvancedSearchModal } = useModal(MODALS.ADVANCED_SEARCH);

  const removeFilter = (filter: IAggregationElement) => {
    if (!filter) return;

    if (filter.filterType === FILTER_TYPES.TREE && filter.rawFilterValue && filter.backendName) {
      dispatch(
        filtersActions.setFilterTree({
          id: filter.backendName,
          data: without(filter.rawValues, filter.rawFilterValue),
        }),
      );

      dispatch(searchActions.setIsElasticEnabled(true));

      return;
    }

    if (filter.filterType === FILTER_TYPES.TREE) {
      dispatch(filtersActions.removeFilterTree({ filter: filter.backendName }));

      dispatch(searchActions.setIsElasticEnabled(true));

      return;
    }

    if (filter.filterType === FILTER_TYPES.NUMERICAL) {
      dispatch(filtersActions.removeFilter({ filter: filter.backendName }));

      dispatch(filtersActions.addIdToNullList(filter.backendName));

      dispatch(searchActions.setIsElasticEnabled(true));

      return;
    }

    dispatch(filtersActions.removeFilter({ filter: filter.backendName }));

    dispatch(searchActions.setIsElasticEnabled(true));
  };

  const modifiedFiltersToDisplay = useMemo(() => {
    return filtersToDisplay.flatMap((filter) => {
      if (filter.filterType !== FILTER_TYPES.TREE || !FILTERS_TO_AGGREGATE.includes(filter.backendName ?? ''))
        return filter;

      const numberOfCheckedFilterItems = filter.parsedValues?.length ?? 0;

      if (numberOfCheckedFilterItems > 4) {
        return {
          ...filter,
          tooltipTitle: filter.parsedValues?.join(', '),
          value: `${filter.sectionLabel} (${numberOfCheckedFilterItems})`,
        };
      }

      if (!filter.parsedValues) return filter;

      return filter.parsedValues.map((value, index) => ({
        backendName: filter.backendName,
        value,
        role: ELEMENT_ROLES.filter,
        filterType: FILTER_TYPES.TREE,
        rawFilterValue: filter.rawValues?.[index],
        rawValues: filter.rawValues,
      })) as IAggregationElement[];
    });
  }, [filtersToDisplay]);

  return showSavedFilters ? (
    <>
      {modifiedFiltersToDisplay?.map((filter) => (
        <Tooltip
          placement="top"
          arrow
          classes={{ tooltip: 'max-w-[320px] bg-[#484848] p-2', arrow: 'text-[#484848]' }}
          title="Add more filters or adjust their values in the Filters panel. Create complex queries with the Advanced menu."
          key={`${filter.value}-${filter.backendName}`}
        >
          <div className="mt-2 mr-2">
            <FilterChip
              label={filter.value}
              onClick={() => openAdvancedSearchModal()}
              onClose={(e) => {
                e.preventDefault();
                removeFilter(filter);
              }}
              tooltipTitle={filter.tooltipTitle}
              dataTestId="search-saved-filter"
            />
          </div>
        </Tooltip>
      ))}
    </>
  ) : null;
};
