import React, { useEffect, ReactNode, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useEffectOnce, useSessionStorage } from 'usehooks-ts';

import { useCaseCodePopupConditionally } from '../Dialogs/CaseCodeDialog/use-show-case-code-popup-conditionally';
import { actions as tableActions } from '@/slices/table';
import { actions as searchActions } from '@/slices/search';
import { useQueryColumnMapper } from '@/hooks/queries/column-mapper/use-query-column-mapper';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { useQuerySavedSearches } from '@/hooks/queries/saved-search/use-query-saved-searches';
import { CUSTOM_SCREEN_QUERY_PARAMS, LOCAL_STORAGE_KEYS, ROUTES } from '@/constants';
import { useScreener } from '@/hooks/use-screen';
import { TSecrets } from '@/hooks/queries/use-query-secrets';
import { CASE_CODE_INITIAL_STATE, useMutationLogCaseCode } from '@/hooks/queries/use-mutation-log-case-code';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

const CASE_CODE_UNPROTECTED_ROUTES = [ROUTES.CUSTOM_SEARCH, ROUTES.SEARCH_LISTS, ROUTES.CREATE_LIST_WORKFLOW];

interface IProps {
  children: ReactNode;
}

export const Main = ({ children }: IProps) => {
  useQueryCompaniesLists();
  useQuerySavedSearches();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const columnMapperQuery = useQueryColumnMapper();
  const location = useLocation();
  const { resetAll } = useScreener();
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const [uiSecrets] = useSessionStorage<TSecrets | undefined>(LOCAL_STORAGE_KEYS.UI_SECRETS, undefined);
  const { mutate: logCaseCode } = useMutationLogCaseCode();
  const { handleCaseCodeModalOpen, shouldShowCaseCodePopup } = useCaseCodePopupConditionally();
  const [caseCodeFormData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);
  const isCaseCodeLogged = useRef(false);

  useEffect(() => {
    const columnMapper = columnMapperQuery.data ?? [];

    if (!isEmpty(columnMapper)) {
      const defaultColumns = columnMapper.filter((item) => item.DEFAULT_COLUMN).map((item) => item['Backend Name']);

      dispatch(tableActions.setDefaultColumns(defaultColumns));

      const isSavedSearchPage = location?.pathname?.startsWith(`${ROUTES.SAVED_SEARCH}/`);

      const wizardSource = location?.search.includes(CUSTOM_SCREEN_QUERY_PARAMS.source.values.WIZARD);

      if (!isSavedSearchPage && !wizardSource) {
        dispatch(tableActions.setVisibleColumns(['__check__'].concat(defaultColumns)));
      }
    }
  }, [columnMapperQuery.data, dispatch, location?.pathname, location?.search]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['filter-tree'],
    });
  }, [location, queryClient]);

  useEffectOnce(() => {
    dispatch(searchActions.setIsElasticEnabled(true));

    return () => {
      resetAll();
    };
  });

  const isCaseCodeUnprotectedRoute = CASE_CODE_UNPROTECTED_ROUTES.some((route) =>
    location?.pathname?.startsWith(route),
  );

  const isRootRoute = location?.pathname === '/' || location?.pathname === '';

  if (shouldShowCaseCodePopup && !isCaseCodeUnprotectedRoute && !isRootRoute && isAuth) {
    handleCaseCodeModalOpen();
  }

  useEffect(() => {
    if (!caseCodeFormData?.shouldBeLogged || !uiSecrets || isCaseCodeLogged.current) return;

    isCaseCodeLogged.current = true;

    logCaseCode(caseCodeFormData, {});
  }, [caseCodeFormData, logCaseCode, uiSecrets]);

  return <main data-testid="appComponent">{children}</main>;
};
