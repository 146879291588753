import React from 'react';

import { IconSimilarCompany } from '@/Components/Icons/IconSimilarCompany';

interface IProps {
  logo?: string;
}

export const CompanyLogo = ({ logo }: IProps) => (
  <span className="border border-[#ddd] p-1 rounded">
    {logo ? (
      <div
        className="w-[40px] h-[40px] bg-no-repeat bg-contain bg-center"
        style={{ backgroundImage: `url(${logo})` }}
      />
    ) : (
      <div className="w-[40px] h-[40px] flex items-center justify-center">
        <IconSimilarCompany />
      </div>
    )}
  </span>
);
