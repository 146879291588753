import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { range } from 'lodash';

export const WizardDialogLoadingScreen = () => (
  <div className="grid gap-3 p-4">
    <Skeleton
      variant="rounded"
      className="bg-[#0000000f] rounded-2xl"
      height={40}
    />
    <Skeleton
      variant="rounded"
      className="bg-[#0000000f] rounded-2xl"
      height={50}
    />

    {range(2).map((idx) => (
      <Skeleton
        key={idx}
        variant="rounded"
        className="bg-[#0000000f] rounded-2xl"
        height={100}
      />
    ))}
    <Skeleton
      variant="rounded"
      className="bg-[#0000000f] rounded-2xl"
      height={50}
    />
  </div>
);
