import React from 'react';
import cn from 'classnames';

import { AppDropdownItemType } from './types';

interface IProps {
  title: string;
  list: AppDropdownItemType[];
  onClick: (item: AppDropdownItemType) => void
}

export const AppDropdownItem = (props: IProps) => (
  <div className='flex flex-col gap-2'>
    <span className='text-[#484848] text-xs font-medium uppercase'>{props.title}</span>

    <div className='flex flex-col gap-3.5 mt-4'>
      {props.list.map(item => {
        const isFind = item.label === 'Find';

        return (
          <div
            key={item.id}
            className={cn('flex flex-col gap-1 relative border border-solid border-[#999] rounded-lg py-5 px-4 group transition-colors cursor-pointer', {
              'bg-[#2e3f4c]': isFind,
            })}
            onClick={() => props.onClick(item)}
          >
            {item.badge && (
              <span className={cn('absolute top-[12px] right-[12px] text-white font-medium text-xs py-1 px-1.5 rounded', item.badge.color)}>
                {item.badge.text}
              </span>
            )}
            <span
              className={cn('font-medium text-lg', {
                'text-white': isFind,
                'text-[#2e3f4c]': !isFind,
              })}
            >
              {item.label}
            </span>
            <span
              className={cn('text-sm w-[80%]', {
                'text-white': isFind,
                'text-[#666]': !isFind,
              })}
            >
              {item.text}
            </span>
          </div>
        );
      })}
    </div>
  </div>
);
