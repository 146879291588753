import { TElasticTableDataValue } from '@/types';

export const ucFirstLetter = (string: string) => string?.charAt(0).toUpperCase() + string?.slice(1);

export const spaceToDash = (string?: string) => string?.replace(/ /g, '-').toLowerCase();

export const clearString = (string?: string) =>
  string
    ?.replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s\s+/g, ' ')
    .trim()
    .toLowerCase();

export const removeTrailingSlash = (string?: string) => string?.replace(/\/+$/, '');

export const formatCompanyDescription = (description?: string) => {
  if (!description) return;

  const startsWithSource = description.startsWith('Source');

  if (!startsWithSource) {
    const trimmedDescription = description.trim();

    return {
      source: undefined,
      description: trimmedDescription,
      formattedDescription: trimmedDescription,
    };
  }

  if (startsWithSource) {
    const sourceRegexp = /Source ([A-Za-z -]+):/g;
    const multipleSpaceRegexp = /\s\s+/g;

    const sources: string[] = Array.from(description.matchAll(sourceRegexp), (m) => m[1].trim());
    const rawDescription: string = description.replace(sourceRegexp, '').replace(multipleSpaceRegexp, ' ').trim();

    const joinedSources = sources.join(', ');
    const formattedDescription = sources.length ? `${rawDescription} - Source: ${joinedSources}` : rawDescription;

    return {
      source: joinedSources,
      description: rawDescription,
      formattedDescription,
    };
  }
};

export const isStringPositiveNumber = (string: string) => {
  const number = Math.floor(Number(string));

  return number !== Infinity && String(number) === string && number > 0;
};

export const validateEmail = (email: string) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};

export const formatTooltipMetadata = (meta: string) => {
  const formatted = meta
    .replace(/([|,;])\s*/g, '<br>')
    .replace(/\.\s*(?![a-z\d])/gi, '<br>')
    .replace(/\b\d+\b/g, (number) => {
      if (number.length === 4 && /^[12][0-9]{3}$/.test(number)) {
        return number;
      }

      return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    });

  return formatted;
};

export const formatTooltipValue = (value: TElasticTableDataValue) => {
  const stringValue = String(value);
  const number = Number(stringValue.replace(/,/g, ''));
  const isYear = stringValue.length === 4 && /^\d{4}$/.test(stringValue) && number <= new Date().getFullYear();

  if (isYear) return stringValue;

  return !isNaN(number) ? number.toLocaleString() : stringValue;
};

export const BRIGHT_QUERY_DATA_SOURCE = 'BrightQuery';

const SOURCES = [BRIGHT_QUERY_DATA_SOURCE, 'CapitalIQ', 'ZoomInfo', 'GainPro'];

export const getYearFromMetadata = <T extends object>(
  row: T | undefined,
  valueFieldName: keyof T,
  metadataFieldName: keyof T,
) => {
  if (!row) return;

  const valueField = row[valueFieldName] as string | number | undefined;
  const metadataField = row[metadataFieldName] as string | undefined;

  if (!valueField || !metadataField) return;

  const indexOfRevenue = metadataField.indexOf(String(valueField));

  const sourceSlicedMetadata = metadataField.slice(0, indexOfRevenue);

  const revenueSource = SOURCES.filter((source) => sourceSlicedMetadata.endsWith(`${source}: `))?.[0];

  if (!revenueSource) return;

  const yearMetadataLabel = `${revenueSource} year end: `;

  const indexOfYearMetadata = metadataField.indexOf(yearMetadataLabel);

  if (indexOfYearMetadata === -1) return;

  const yearSlicedMetadata = metadataField.slice(indexOfYearMetadata + yearMetadataLabel.length);

  const year = yearSlicedMetadata.slice(0, 4);

  if (isNaN(Number(year))) return;

  return {
    year,
    label: ` (${year})`,
    source: revenueSource,
  };
};

export const checkIfStringStartsWith = (string: string, substrings: string[]) =>
  substrings.some((substr) => string.startsWith(substr));
