import { useQuery } from '@tanstack/react-query';

import { getTableFilters } from '@/services/api/table';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const useColumnFilters = () => {
  const currency = useShallowSelector((state) => state.config.currency);

  return useQuery({
    queryKey: ['column-filters', currency],
    queryFn: () => getTableFilters(currency),
    enabled: !!currency,
    cacheTime: Infinity,
    staleTime: Infinity,
  })
}
