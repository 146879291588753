import { useQuery } from '@tanstack/react-query';
import hash from 'object-hash';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { searchSimilarCompanies } from '@/services/api/similar-companies';
import { getFilters } from '@/Utils/filters';

export const useQuerySimilarCompaniesSearch = (bain_id: number, companyDescription: string) => {
  const currency = useShallowSelector((state) => state.config.currency);
  const storeFilters = useShallowSelector((state) => state.filters);
  const filters = getFilters(storeFilters) as Record<string, string[]>;
  const { isFiltersDrawerOpen } = useShallowSelector((state) => state.search);
  const currentHash = hash({ bain_id, companyDescription, filters, isFiltersDrawerOpen });

  return useQuery(['similar-companies-search', currentHash], () => searchSimilarCompanies(currency, { bain_id, filters }), {
    keepPreviousData: true,
    enabled: !!companyDescription && !isFiltersDrawerOpen,
    retry: 3,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
