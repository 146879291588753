import { useEffect, useState } from 'react';
import hash from 'object-hash';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

interface UseFiltersHasChangeProps {
  initialCondition?: boolean;
}

export const useFiltersHasChange = ({ initialCondition = true }: UseFiltersHasChangeProps) => {
  const allAppliedFilters = useShallowSelector((state) => state.filters);
  const [inItialFiltersHash, setInitialFiltersHash] = useState<string>('');
  const currentHash = hash(allAppliedFilters);
  const hasChange = inItialFiltersHash !== currentHash;

  useEffect(() => {
    if (initialCondition && !inItialFiltersHash) {
      setInitialFiltersHash(hash(allAppliedFilters));
    }
  }, [allAppliedFilters, inItialFiltersHash, initialCondition]);

  return {
    hasChange,
    setInitialFiltersHash,
    currentHash,
  };
};
