import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { keyBy, mapValues } from 'lodash';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getElasticData } from '@/services/api/elastic-search';
import { IElasticResponse } from '@/types';

export const useQueriesChartCompany = (currentBainId?: string) => {
  const compareChartList = useShallowSelector(state => state.charts.compare);
  const currency = useShallowSelector((state) => state.config.currency);
  const bainIds = useMemo(() => currentBainId ?
    ([currentBainId, ...compareChartList.map(item => item.bainId)]) : [],
    [currentBainId, compareChartList],
  );

  const queries = useQueries({
    queries: bainIds.map(bainId => ({
      queryKey: ['chart-company', bainId],
      queryFn: () => getElasticData({
        currency,
        body: { is_lucene: true, filters: {}, company_search: '' },
        pinnedCompanyIds: [Number(bainId)],
      }),
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 1,
      select: ({ data }: IElasticResponse) => {
        return {
          bainId,
          name: data[0].self_firmo_name___,
        };
      },
    })),
  });

  const isLoading = useMemo(() => queries.some((query) => query.status === 'loading'), [queries]);
  const data: Record<string, string> = useMemo(() =>
    mapValues(keyBy(queries.map(item => item.data), 'bainId'), 'name'),
    [queries],
  );

  return {
    data,
    isLoading,
  };
};
