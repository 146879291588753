import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { groupedAutocompleteCompanySearch } from '@/services/api/autocomplete-company-search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

type QueryType = {
  searchText: string;
  industry?: string;
  bain_id?: string;
};

export const useMutationGroupedMatchCompanySearch = () => {
  const { enqueueSnackbar } = useSnackbar();
  const currency = useShallowSelector((state) => state.config.currency);

  return useMutation(
    (data: QueryType) => groupedAutocompleteCompanySearch({
      company: data.searchText,
      bain_id: data.bain_id,
      currency,
    }),
    {
      onError: () =>
        enqueueSnackbar('Failed to fetch companies list. Please try again or contact support.', {
          variant: 'error',
        }),
    },
  );
};
