import React, { useRef } from 'react';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Skeleton from '@mui/material/Skeleton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import { isEmpty } from 'lodash';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { AxisFormat, ChartDatasetType, Currency } from '@/types';
import { formatNumericChartValue } from '@/Utils/charts';

const getOptions = (config: CommonChartConfigType, currency: Currency): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback: function (tick) {
          const value = `${this.getLabelForValue(tick as number)}`.slice(-2);

          return `'${value}`;
        },
      },
    },
    'y-axis-0': {
      position: 'left',
      grid: {
        display: true,
        color: '#ddd',
      },
      border: {
        dash: [2, 2],
      },
      beginAtZero: true,
      ticks: {
        callback: function (value) {
          return formatNumericChartValue({
            value,
            yAxisType: config.yAxis[0].yAxisType,
            currency,
          });
        },
      },
    },
    ...(config.yAxis[1] && {
      'y-axis-1': {
        grid: {
          display: false,
        },
        position: 'right',
        ticks: {
          callback: function (value) {
            return formatNumericChartValue({
              value,
              yAxisType: config.yAxis[1].yAxisType,
              currency,
            });
          },
        },
      },
    }),
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
    },
    tooltip: {
      enabled: true,
      position: 'nearest',
      yAlign: 'bottom',
      xAlign: 'center',
      caretSize: 8,
      caretPadding: 18,
      callbacks: {
        label: function (context) {
          if (context.parsed.y !== null) {
            const { label } = context.dataset;

            if (label) {
              const company = label.split(':')[0];

              const axis = config.yAxis.find((_, index) => index === Number(context.dataset.yAxisID?.split('-').pop()));

              return `${company}: ${formatNumericChartValue({
                value: context.parsed.y,
                yAxisType: axis?.yAxisType,
                currency,
              })}`;
            }
          }
        },
      },
    },
  },
});

export type CommonChartConfigType = {
  id: number;
  slug: string;
  title: string;
  yAxis: { chartType: string; field: string; label: string; yAxisType: AxisFormat }[];
  xAxis: { field: string }[];
};

interface IProps {
  chartConfig: CommonChartConfigType;
  data: ChartDatasetType;
  isLoading: boolean;
  handlePushToFinancials: () => void;
}

export const OverviewChart = ({ chartConfig, data, isLoading, handlePushToFinancials }: IProps) => {
  const ref = useRef<Chart<'bar'>>(null);
  const currency = useShallowSelector((state) => state.config.currency);
  const xAxisField = chartConfig.xAxis[0].field ?? '';
  const options = getOptions(chartConfig, currency);

  const chartData: ChartData<'bar'> = {
    labels: data?.data?.map((item) => Number(item[xAxisField])),
    // @ts-ignore
    datasets: chartConfig.yAxis.map((yItem, idx) => {
      const chartType = yItem.chartType?.toLowerCase();
      const isBar = chartType === 'bar';

      return {
        label: yItem.label,
        type: chartType,
        data: data?.data?.map((item) => Number(item[yItem.field])),
        ...(isBar && {
          type: chartType,
          backgroundColor: '#728a9d',
        }),
        ...(!isBar && {
          borderColor: '#02c7e6',
          backgroundColor: '#02c7e6',
          pointRadius: 0,
          pointHoverBackgroundColor: '#0484e7',
          pointHoverBorderColor: '#fff',
        }),
        yAxisID: `y-axis-${idx}`,
        order: isBar ? 1 : 0,
      };
    }),
  };

  if (isLoading) {
    return (
      <Skeleton
        height={390}
        variant="rounded"
        className="bg-[#0000000f] break-inside-avoid"
      />
    );
  }

  if (isEmpty(data) || isEmpty(data.data)) {
    return null;
  }

  return (
    <div className="p-6 bg-white rounded break-inside-avoid shadow-[0_0_4px_0_rgba(95,95,95,0.25)]">
      <div className="flex items-center justify-between mb-4 min-h-9">
        <span className="text-base font-medium text-[#484848]">{chartConfig.title}</span>

        <IconButton
          onClick={handlePushToFinancials}
          size="small"
          id={`CompanyProfile${chartConfig.slug}PreviewChartRedirectButton`}
        >
          <KeyboardArrowRightIcon />
        </IconButton>

        {/* <CompareButton chartId={chartConfig.id} selectCallback={handlePushToFinancials} /> */}
      </div>

      <div className="min-h-[290px]">
        <Bar
          ref={ref}
          data={chartData}
          options={options}
        />
      </div>
    </div>
  );
};
