import { ChartDataset } from 'chart.js';
import dayjs from 'dayjs';

import { ChartConfigType, ChartDatasetType } from '@/types';

export const groupLegendData = (datasets: ChartDataset[]) => {
  const groupedLegends = datasets.reduce((acc, item) => {
    const [id, text] = item.label?.split(':') ?? [];

    if (!acc.has(id)) {
      acc.set(id, []);
    }

    acc.get(id).push({
      id,
      text,
      color: item.backgroundColor || item.borderColor,
    });

    return acc;
  }, new Map());

  return groupedLegends;
};

export const getUniqueLabels = (
  mainDataset: ChartDatasetType,
  comparisonDatasets: ChartDatasetType[],
  config: ChartConfigType,
) => {
  const labels = new Set<string>();

  mainDataset.data.forEach((item) => labels.add(item[config.XAXIS] as string));
  comparisonDatasets.forEach((dataset) => dataset.data.forEach((item) => labels.add(item[config.XAXIS] as string)));

  return Array.from(labels).sort((a, b) => dayjs(a).valueOf() - dayjs(b).valueOf());
};
