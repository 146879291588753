import React from 'react';

import { numberFormatterCustom } from '../common';
import { CompanyLogo } from '../CompanyLogo';
import { CURRENCIES } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { ensureFullUrl, getPrettyLink } from '@/Utils';

interface IProps {
  logo?: string;
  company?: string;
  country?: string;
  industry?: string;
  revenue?: number;
  website?: string;
}

const Item = ({ value, isLink = false }: { value: string | number, isLink?: boolean }) => (
  <span className="text-xs text-[#484848] after:content-['|'] after:ml-1 after:text-[#ddd] last:after:content-none">
    {isLink
      ? <a href={ensureFullUrl(value as string)} target="_blank" rel="noreferrer" className="underline">{getPrettyLink(value as string)}</a>
      : value}
  </span>
);

export const SearchItem = (props: IProps) => {
  const currency = useShallowSelector((state) => CURRENCIES[state.config.currency].label);

  return (
    <div className="flex gap-4 items-center">
      <CompanyLogo logo={props.logo} />

      <div className="flex flex-col gap-1">
        <span className="font-semibold text-[#484848]">{props.company}</span>

        <div className="flex items-center gap-1">
          {props.website && <Item value={props.website} isLink />}
          {props.country && <Item value={props.country} />}
          {props.industry && <Item value={props.industry} />}
          {props.revenue && (
            <Item value={`${currency}${numberFormatterCustom(props.revenue)}`} />
          )}
        </div>
      </div>
    </div>
  );
};
