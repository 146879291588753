import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Tooltip } from '@mui/material';
import SourceLogo from '../SourceLogo/SourceLogo';
import { BRIGHT_QUERY_DATA_SOURCE } from '@/Utils/text';
import { DATA_SOURCE_ID, DATA_SOURCE_IDS } from '@/constants';

const getDataSourceByLabel = (label: string) =>
  [...Object.entries(DATA_SOURCE_IDS), ['self_ids_id__ciq_', 'CapitalIQ']].find(
    ([, sourceLabel]) => label.toLowerCase() === sourceLabel.toLowerCase(),
  )?.[0];

interface IProps {
  currentDataSource: string;
  preferredDataSource?: string | null;
  dataSourcesAvailable: string[];
  inputLabelId: string;
  selectLabelId: string;
  selectId: string;
  onChange: (event: SelectChangeEvent<string | null>) => void;
}

export const DataSourceMenu = ({
  currentDataSource,
  preferredDataSource,
  dataSourcesAvailable,
  inputLabelId,
  selectLabelId,
  selectId,
  onChange,
}: IProps) => {
  const getLabelWithIcon = (dataSource: string) => {
    const sourceColumnId = getDataSourceByLabel(dataSource);

    return (
      <div className="flex gap-1 items-center">
        {sourceColumnId ? (
          <div className="mr-1">
            <SourceLogo
              columnId={sourceColumnId as DATA_SOURCE_ID}
              size="small"
            />
          </div>
        ) : null}
        {dataSource}
      </div>
    );
  };

  return (
    <div className="flex justify-end">
      <FormControl
        sx={{ minWidth: 150 }}
        size="small"
      >
        <InputLabel id={inputLabelId}>Data Source</InputLabel>
        <Select
          labelId={selectLabelId}
          id={selectId}
          value={currentDataSource}
          label="Data Source"
          onChange={onChange}
          disabled={!dataSourcesAvailable || dataSourcesAvailable.length < 2}
          renderValue={(value: string) => <>{getLabelWithIcon(value)}</>}
        >
          {dataSourcesAvailable.map((dataSource) => {
            return (
              <MenuItem
                value={dataSource}
                key={dataSource}
              >
                <div className="flex gap-1 items-center grow justify-between">
                  <div className="flex gap-1 items-center ">
                    {getLabelWithIcon(dataSource)}
                    {dataSource === preferredDataSource ? <span className="text-[#484848]">- Recommended</span> : null}
                  </div>

                  {dataSource === BRIGHT_QUERY_DATA_SOURCE ? (
                    <Tooltip title="Source is using a mathematical approach to estimate the company's financials.">
                      <span className="inline-block ml-16 bg-[#FEF9DC] py-[2px] px-2 font-medium rounded">
                        Estimated
                      </span>
                    </Tooltip>
                  ) : null}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
