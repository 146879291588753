import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import { WizardStepper } from './WizardStepper';

export interface WizardDialogHeaderProps {
  title: string;
  stepTitle: string;
  stepDescription?: string;
  numberOfSteps: number;
  activeStep: number;
  handleClose: () => void;
  iconLink?: string;
  hideStepper?: boolean;
}

export const WizardDialogHeader = ({
  title,
  stepTitle,
  stepDescription,
  numberOfSteps,
  activeStep,
  handleClose,
  iconLink,
  hideStepper,
}: WizardDialogHeaderProps) => {
  return (
    <div className="px-4 pt-4 sticky top-0 bg-white z-20">
      <div className="flex justify-between items-center pb-4">
        <div className="flex gap-4">
          {iconLink ? (
            <img
              className="w-6 h-6 inline-block"
              src={iconLink}
              alt={`${title} icon`}
            />
          ) : null}
          <DialogTitle
            data-testid="wizard-dialog-header"
            className="flex text-base font-medium p-0 text-[#484848] capitalize"
          >
            {stepTitle}
          </DialogTitle>
        </div>

        <div className="flex gap-4">
          {hideStepper ? null : (
            <div style={{ width: numberOfSteps * 50 }}>
              <WizardStepper
                numberOfSteps={numberOfSteps}
                activeStep={activeStep}
              />
            </div>
          )}

          <CloseIcon
            data-testid="wizard-close-button"
            fontSize="small"
            className="cursor-pointer fill-[#484848]"
            onClick={handleClose}
          />
        </div>
      </div>
      <div className="w-full h-[1px] bg-[#F5F5F5]" />
      {stepDescription ? <span className="inline-block my-4 text-sm">{stepDescription}</span> : null}
    </div>
  );
};
