import React, { SVGProps } from 'react';

export const IconLocation = (props: SVGProps<SVGSVGElement>) => {
  const { fill, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        fill={fill ?? '#484848'}
        fillRule="evenodd"
        d="M8 5.333a1.001 1.001 0 0 0 0 2 1.001 1.001 0 0 0 0-2Zm0 3.334a2.336 2.336 0 0 1-2.333-2.334A2.336 2.336 0 0 1 8 4a2.336 2.336 0 0 1 2.333 2.333A2.336 2.336 0 0 1 8 8.667Zm0-7.334c-2.94 0-5.333 2.37-5.333 5.282 0 3.65 4.699 7.72 4.899 7.891a.665.665 0 0 0 .868 0c.2-.171 4.9-4.241 4.9-7.89 0-2.913-2.393-5.283-5.334-5.283Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
