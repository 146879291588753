import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { DATA_SOURCE_ID } from '@/constants';

interface IProps {
  columnId: DATA_SOURCE_ID;
  size?: 'small' | 'medium';
  title?: string;
  sourceId?: string;
}

export default function SourceLogo({ columnId, size = 'medium', sourceId, title }: IProps) {
  const logo = (
    <div
      className={classNames('bg-no-repeat bg-cover bg-center rounded', {
        'w-[20px] h-[20px]': size === 'small',
        'w-[24px] h-[24px]': size === 'medium',
      })}
      style={{ backgroundImage: `url(/data-sources/${columnId}.png)` }}
    />
  );

  return title ? (
    <Tooltip
      title={
        <div className="flex flex-col gap-1">
          <span>{title}</span>

          {sourceId && <span>Company ID: {sourceId}</span>}
        </div>
      }
      enterDelay={300}
    >
      {logo}
    </Tooltip>
  ) : (
    <>{logo}</>
  );
}
