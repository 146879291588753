import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  showGlassdoor: false,
};

export const { reducer, actions } = createSlice({
  name: 'sentiment',
  initialState,
  reducers: {
    showGlassdoor: (state, { payload }: PayloadAction<boolean>) => {
      state.showGlassdoor = payload;
    },
  },
});

export type TState = typeof initialState;
