import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { SkeletonLoader } from '../../../common/SkeletonLoader';
import { GlassdoorSentiment } from './Glassdoor';
import { UnlockView } from './Glassdoor/UnlockView';
import { useQueryCompanySentiment } from '@/hooks/queries/company-profile/use-query-company-sentiment';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/sentiment';

interface IProps {
  bainId: string;
}

export const Sentiment = ({ bainId }: IProps) => {
  const dispatch = useDispatch();
  const showGlassdoor = useShallowSelector((state) => state.sentiment.showGlassdoor);
  const query = useQueryCompanySentiment(bainId);

  const onShow = () => {
    dispatch(actions.showGlassdoor(true));
  };

  if (query.isFetching) {
    return <SkeletonLoader />
  }

  return (
    <div className="grid grid-cols-2 gap-4 my-4">
      <div className={cn('bg-white rounded border border-[#f5f5f5] break-inside-avoid text-[#484848] shadow-[0_1px_3px_0_rgba(46,48,48,0.14)]', {
        'p-6': showGlassdoor,
      })}>

        {showGlassdoor
          ? <GlassdoorSentiment data={query.data} />
          : <UnlockView onClick={onShow} />
        }
      </div>
    </div>
  );
};
