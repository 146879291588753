import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { groupedAutocompleteCompanySearch } from '@/services/api/autocomplete-company-search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const useQueryGroupedMatchCompanySearch = (searchText: string, enable = true, bain_id?: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const { enqueueSnackbar } = useSnackbar();
  const currency = useShallowSelector((state) => state.config.currency);

  return useQuery(
    ['grouped-match-company-search', searchText, bain_id, currency],
    () =>
      groupedAutocompleteCompanySearch({
        currency,
        company: searchText,
        bain_id,
      }),
    {
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: isAuth && enable,
      onError: () =>
        enqueueSnackbar('Failed to fetch companies list. Please try again or contact support.', {
          variant: 'error',
        }),
    },
  );
};
