import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';

import Routes from './pages/routes';
import { NotificationCloseAction } from './Components/Shared/Notifications/NotificationCloseAction';
import { Main } from './Components/Main';
import Header from './Components/Header';
import { CaseCodeDialog } from './Components/Dialogs/CaseCodeDialog';
import { ComparisonExportDialog } from './Components/Dialogs/ComparisonExportDialog';
import { AdvancedSearchDialog } from './Components/Dialogs/AdvancedSearchDialog';
import { SearchCompaniesHistoryDialog } from './Components/Dialogs/SearchCompaniesHistoryDialog';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import ExportDialog from './Components/Dialogs/ExportDialog';
import { CreateCompaniesListDialog } from './Components/Dialogs/CreateCompaniesListDialog';
import { UpdateCompaniesListDialog } from './Components/Dialogs/UpdateCompaniesListDialog';
import { CreateSavedSearchDialog } from './Components/Dialogs/CreateSavedSearchDialog';
import { UpdateSavedSearchDialog } from './Components/Dialogs/UpdateSavedSearchDialog';
import { SimilarCompaniesLoadingDialog } from './Components/Dialogs/SimilarCompaniesLoadingDialog';
import { ShareListWithCollaboratorsDialog } from './Components/Dialogs/ShareListWithCollaboratorsDialog';
import { CompaniesListHistoryDialog } from './Components/Dialogs/CompaniesListHistoryDialog';
import { StarterPackWizardDialog } from './Components/Dialogs/StarterPackWizardDialog';
import { SemanticSearchLoadingDialog } from './Components/Dialogs/SemanticSearchLoadingDialog';
import { WizardLoadingDialog } from './Components/Dialogs/WizardLoadingDialog';
import { ConfirmDialog } from './Components/Dialogs/ConfirmDialog';
import { WelcomeDialog } from './Components/Dialogs/WelcomeDialog';
import awsService from './awsService';
import { theme } from './theme';
import { GlobalCss } from './globalStyle';
import { IconInfo } from './Components/Icons/IconInfo';
import { IconError } from './Components/Icons/IconError';
import { IconSuccess } from './Components/Icons/IconSuccess';
import { IconWarning } from './Components/Icons/IconWarning';
import { useQuerySecrets } from '@/hooks/queries/use-query-secrets';

import './App.scss';

awsService.initializeAws();

const App = () => {
  useQuerySecrets();

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={4500}
          preventDuplicate
          action={NotificationCloseAction}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          iconVariant={{
            info: <IconInfo className="SnackbarItem-left-icon" />,
            error: <IconError className="SnackbarItem-left-icon" />,
            success: <IconSuccess className="SnackbarItem-left-icon" />,
            warning: <IconWarning className="SnackbarItem-left-icon" />,
          }}
        >
          <Main>
            <Header />
            <Routes />

            {/** Render modals here */}
            <ExportDialog />
            <ComparisonExportDialog />
            <CaseCodeDialog />
            <AdvancedSearchDialog />
            <SearchCompaniesHistoryDialog />
            <CreateCompaniesListDialog />
            <UpdateCompaniesListDialog />
            <CreateSavedSearchDialog />
            <UpdateSavedSearchDialog />
            <SimilarCompaniesLoadingDialog />
            <WizardLoadingDialog />
            <ShareListWithCollaboratorsDialog />
            <CompaniesListHistoryDialog />
            <StarterPackWizardDialog />
            <SemanticSearchLoadingDialog />
            <ConfirmDialog />
            <WelcomeDialog />
            {/** Render modals here */}

            <GlobalCss />
          </Main>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
