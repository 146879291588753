import React, { useMemo, useState } from 'react';

import { useHistory } from 'react-router';
import { SemanticSearchWorkflowStep, CreateListWorkflowSteps, StepProps, SEMANTIC_OPTION_STEPS } from './types';
import { PickCreateListOptionStep } from './steps/PickCreateListOptionStep';
import { ProvideSemanticSearchDescriptionStep } from './steps/ProvideSemanticSearchDescriptionStep';
import { LoadingScreenStep } from './steps/LoadingScreenStep';
import { SemanticFiltersAndKeywordsStep } from './steps/SemanticFiltersAndKeywordsStep';
import { AiRefinedSemanticSearchDescriptionStep } from './steps/AiRefinedSemanticSearchDescriptionStep';
import { HOME_PAGE_SLUG } from '@/constants';

const stepContentTypes: Record<SemanticSearchWorkflowStep, React.FC<StepProps>> = {
  [CreateListWorkflowSteps.PICK_CREATE_LIST_OPTION]: PickCreateListOptionStep,
  [CreateListWorkflowSteps.PROVIDE_SEMANTIC_SEARCH_DESCRIPTION]: ProvideSemanticSearchDescriptionStep,
  [CreateListWorkflowSteps.LOADING_SCREEN]: LoadingScreenStep,
  [CreateListWorkflowSteps.SEMANTIC_FILTERS_AND_KEYS]: SemanticFiltersAndKeywordsStep,
  [CreateListWorkflowSteps.AI_REFINED_SEMANTIC_SEARCH_DESCRIPTION]: AiRefinedSemanticSearchDescriptionStep,
};

const FIRST_STEP_NUMBER = 0;

export const CreateListWorkflow = () => {
  const { push } = useHistory();
  const [activeStep, setActiveStep] = useState(FIRST_STEP_NUMBER);
  const [stepsSet, setStepsSet] = useState<Partial<SemanticSearchWorkflowStep>[]>(SEMANTIC_OPTION_STEPS);
  const [userDescription, setUserDescription] = useState('');

  const stepName = stepsSet[activeStep];

  const Component = stepContentTypes[stepName];

  const handleClose = () => push(HOME_PAGE_SLUG);

  const progress = useMemo(() => {
    const progressBarFragments = stepsSet.length + 1;

    const currentStepFragments = activeStep + 1;

    return (currentStepFragments / progressBarFragments) * 100;
  }, [activeStep, stepsSet.length]);

  return (
    <Component
      progress={progress}
      userDescription={userDescription}
      setUserDescription={setUserDescription}
      setStep={setActiveStep}
      setStepsSet={setStepsSet}
      isFirstStep={activeStep === FIRST_STEP_NUMBER}
      handleClose={handleClose}
    />
  );
};
