import React, { useCallback, useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch } from 'react-redux';
import { utils, writeFile } from 'xlsx';
import { isEmpty, isNil, uniq } from 'lodash';
import { Chart } from 'chart.js';
import 'chart.js/auto';

import { BarChart, LineChart, StackedBarChart, LineOverBarChart, SankeyChart } from '@/Components/Shared/Charts';
import { ChartConfigType, ChartDatasetType, DatumDatum } from '@/types';
import { actions } from '@/slices/charts';
import { CompareChartsType } from '@/types/state/charts';
import { DATA_SOURCE_IDS } from '@/constants';
import { useQueriesChartCompany } from '@/hooks/queries';

export interface IChartCommonProps {
  data: ChartDatasetType;
  comparisonDatasets: ChartDatasetType[];
  isLoading: boolean;
  section: string;
  params?: { row: DatumDatum };
  config: ChartConfigType;
}

const filterBySource = (
  data: ChartDatasetType,
  allSources: (string | number)[],
  preferredDataSource: string | number,
) => {
  if (!allSources.length) return data;

  return {
    bainId: data.bainId,
    data: data.data.filter(({ DATA_SOURCE }) => DATA_SOURCE === preferredDataSource),
  };
};

export const ChartCommon = ({
  data: allSourcesData,
  comparisonDatasets: allSourcesComparisonDatasets,
  params,
  section,
  isLoading,
  config,
}: IChartCommonProps) => {
  const dispatch = useDispatch();
  const { data: companies } = useQueriesChartCompany(allSourcesData?.bainId);
  const type = config.CHART_TYPE;
  const label = config.TITLE;

  //TODO: Handle based on dropdown Data Source
  const allSources = uniq(allSourcesData?.data.map((item) => item.DATA_SOURCE).filter((item) => !isNil(item)));
  const preferredDataSource = allSources.includes(DATA_SOURCE_IDS.self_ids_id__cb_)
    ? DATA_SOURCE_IDS.self_ids_id__cb_
    : allSources[0];

  const data = useMemo(
    () => filterBySource(allSourcesData, allSources, preferredDataSource),
    [allSources, allSourcesData, preferredDataSource],
  );
  const comparisonDatasets = useMemo(
    () => allSourcesComparisonDatasets.map((dataSet) => filterBySource(dataSet, allSources, preferredDataSource)),
    [allSources, allSourcesComparisonDatasets, preferredDataSource],
  );

  const handleDownloadXlsx = useCallback(() => {
    const wb = utils.book_new();
    const mainWs = utils.json_to_sheet(data.data);

    utils.book_append_sheet(wb, mainWs, companies[data.bainId]);

    comparisonDatasets
      .filter((dataset) => dataset.data.length > 0)
      .forEach((dataset) => {
        const ws = utils.json_to_sheet(dataset.data);

        utils.book_append_sheet(wb, ws, companies[dataset.bainId]);
      });

    writeFile(wb, `${label}-data.xlsx`);
  }, [companies, comparisonDatasets, data, label]);

  const handleRemoveDataset = useCallback(
    (compareData: CompareChartsType) => {
      dispatch(actions.removeCompany(compareData));
    },
    [dispatch],
  );

  const handleDownloadImage = useCallback(
    (ref: React.RefObject<Chart>) => {
      if (ref.current) {
        const link = document.createElement('a');

        link.href = ref.current.toBase64Image('image/png', 1);
        link.download = `${label}-chart.png`;
        link.click();
      }
    },
    [label],
  );

  if (isLoading) {
    return (
      <Skeleton
        height={400}
        variant="rounded"
        className="bg-[#0000000f] rounded-2xl"
        data-testid="skeleton"
      />
    );
  }

  if (isEmpty(data) && !isLoading) {
    return null;
  }

  return (
    <div
      className="bg-white rounded mb-4"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      {type === 'LINE' && (
        <LineChart
          config={config}
          data={data}
          params={params}
          section={section}
          comparisonDatasets={comparisonDatasets}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
          handleRemoveDataset={handleRemoveDataset}
        />
      )}

      {type === 'BAR' && (
        <BarChart
          config={config}
          data={data}
          params={params}
          section={section}
          comparisonDatasets={comparisonDatasets}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
          handleRemoveDataset={handleRemoveDataset}
        />
      )}

      {type === 'STACKED_BAR' && (
        <StackedBarChart
          config={config}
          data={data}
          params={params}
          section={section}
          comparisonDatasets={comparisonDatasets}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
        />
      )}

      {type === 'LineOverBar' && config && (
        <LineOverBarChart
          config={config}
          data={data}
          subType={config.METADATA?.CHART_SUBTYPE}
          comparisonDatasets={comparisonDatasets}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
          handleRemoveDataset={handleRemoveDataset}
        />
      )}

      {type === 'SANKEY' && (
        <SankeyChart
          config={config}
          data={data}
        />
      )}
    </div>
  );
};
