import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import { ICompanySentimentMatch } from '@/types';
import { useMenu } from '@/hooks/use-menu';
import { SearchItem } from '@/Components/Shared/SearchItem';

interface IProps {
  currentCompanyId: number;
  alternativeMatches?: ICompanySentimentMatch[];
  initialCompany: ICompanySentimentMatch;
  onChangeCompany: (company: ICompanySentimentMatch) => void;
}

export const WrongCompany = ({
  currentCompanyId,
  initialCompany,
  alternativeMatches = [],
  onChangeCompany,
}: IProps) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  const onClick = (company: ICompanySentimentMatch) => () => {
    onChangeCompany(company);
    handleClose();
  };

  return alternativeMatches.length ? (
    <div>
      <Button
        id="basic-button"
        aria-haspopup="true"
        className="text-[#2e3f4c] font-medium text-sm"
        onClick={handleClick}
      >
        Wrong Company?
      </Button>

      <Menu
        className="px-0"
        classes={{
          list: 'px-2',
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
      >
        {currentCompanyId !== initialCompany.id && (
          <MenuItem
            onClick={onClick(initialCompany)}
          >
            <SearchItem
              logo={initialCompany.squareLogo}
              company={initialCompany.name}
              website={initialCompany.website}
              industry={initialCompany.industry}
            />
          </MenuItem>
        )}

        {alternativeMatches.filter(item => item.id !== currentCompanyId).map(item => (
          <MenuItem
            key={item.id}
            onClick={onClick(item)}
          >
            <SearchItem
              logo={item.squareLogo}
              company={item.name}
              website={item.website}
              industry={item.industry}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  ) : null;
};
