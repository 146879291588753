import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { generateSemanticBasedSearchPayload } from '@/services/api/elastic-search';
import { ISemanticSearchAIResponse } from '@/types/state/semantic-search';

export const useQueryRefineSemanticDescription = ({
  description,
  enabled,
  onSuccess,
  onError,
}: {
  description: string;
  enabled: boolean;
  onSuccess?: (data: ISemanticSearchAIResponse) => void;
  onError?: () => void;
}) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    ['refine-semantic-description', description],
    async () => generateSemanticBasedSearchPayload(description),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: isAuth && enabled && !!description,
      onError: () => {
        enqueueSnackbar('Failed to refine your description. Please try again or contact support.', {
          variant: 'error',
        });

        onError?.();
      },
      onSuccess,
    },
  );
};
