import React, { FC } from 'react';

import MUISwitch from '@mui/material/Switch';
import { FormControlLabel, Tooltip } from '@mui/material';
import classnames from 'classnames';

interface SwitchProps {
  onToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  isChecked: boolean;
  label?: string;
  dataTestId?: string;
  id?: string;
  labelPlacement?: 'start' | 'end';
  tooltip?: string;
}

export const Switch: FC<SwitchProps> = ({
  onToggle,
  isChecked,
  label: rawLabel,
  dataTestId,
  id,
  labelPlacement = 'start',
  tooltip,
}) => {
  const label = tooltip ? (
    <Tooltip
      title={tooltip}
      placement="right"
    >
      <span>{rawLabel}</span>
    </Tooltip>
  ) : (
    rawLabel
  );

  return (
    <FormControlLabel
      data-testid={dataTestId}
      id={id}
      className="gap-2"
      control={
        <MUISwitch
          checked={isChecked}
          onChange={onToggle}
          className="py-[10px] px-[12px]"
          classes={{
            track: classnames('opacity-100 rounded-[10px]', {
              'bg-[#2E3F4C]': isChecked,
              'bg-[#DDDDDD]': !isChecked,
            }),
            checked: 'text-white',
            thumb: 'border border-[#DDDDDD]',
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};
