import React, { ReactNode } from 'react';
import SanitizeHTML from 'sanitize-html';

interface IProps {
  children: ReactNode;
  className?: string;
}

export const SafeHTML = ({ className = '', children }: IProps) => {
  const unsafe = React.Children.toArray(children)
    .filter((child) => typeof child === 'string')
    .join('');

  const escaped = SanitizeHTML(unsafe, {
    allowedTags: [
      'blockquote',
      'p',
      'a',
      'ul',
      'ol',
      'li',
      'b',
      'i',
      'strong',
      'em',
      'strike',
      'del',
      'br',
      'div',
      'sup',
      'sub',
    ],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src'],
    },
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
  });

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: escaped,
      }}
    />
  );
};
