import React from 'react';

import { Button } from '@mui/material';
import { FirstIcon } from './icons/1icon';
import { SecondIcon } from './icons/2icon';
import { ThirdIcon } from './icons/3icon';
import { UnlockIcon } from './icons/unlock';

const ItemIcon = ({ text, Icon }: { text: string; Icon: () => JSX.Element }) => (
  <div className="flex items-center gap-4">
    <Icon />
    <span>{text}</span>
  </div>
);

interface IProps {
  onClick: () => void;
}

export const UnlockView = ({ onClick }: IProps) => {
  return (
    <div className="flex flex-col rounded">

      <div
        style={{ backgroundImage: 'url(/sentiment/background.png)' }}
        className="text-white px-8 pt-10 pb-16 rounded relative bg-no-repeat bg-cover"
      >
        <span className="font-medium text-2xl">Want to view employee sentiment?</span>

        <div className="flex flex-col gap-6 mt-9 text-sm">
          <ItemIcon text="Determine this company’s overall rating." Icon={FirstIcon} />
          <ItemIcon text="Discover employee generated ratings such as leadership, culture and work life balance." Icon={SecondIcon} />
          <ItemIcon text="Free to request on Helix!" Icon={ThirdIcon} />
        </div>
      </div>

      <div className="flex justify-between items-center p-8">
        <div className="flex flex-col gap-1">
          <span className="text-sm text-[#666666]">Powered by</span>

          <span>
            <img src="/sentiment/glassdoor.png" alt="glassdoor" width={120} height={36} />
          </span>
        </div>

        <Button
          className="bg-[#2e3f4c] text-white h-[48px] px-6"
          endIcon={<UnlockIcon />}
          onClick={onClick}
          id="glassdoor-unlock-button"
        >
          Unlock
        </Button>
      </div>
    </div>
  );
};
