import React, { useState } from 'react';

import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { StepProps } from '../types';

import { WorkflowDialogHeader } from '../../Common/WorkflowDialogHeader';
import { WorkflowDialogFooter } from '../../Common/WorkflowDialogFooter';
import { actions as searchActions } from '../../../../slices/search';
import { useSemanticSearchValueValidation } from './useSemanticSearchValueValidation';
import { useModal } from '@/hooks/use-modal';
import { MODALS, ROUTES } from '@/constants';
import { IconWarning } from '@/Components/Icons/IconWarning';
import { useQueryRefineSemanticDescription } from '@/hooks/queries/semantic-search/use-query-refine-semantic-description';
import { IconInfo } from '@/Components/Icons/IconInfo';

const tryAskingOptions = [
  'Companies specializing in artificial intelligence (AI) and machine learning (ML) solutions.',
  'Manufacturers of renewable energy equipment, such as solar panels or wind turbines.',
  'Companies with expertise in pharmaceuticals or medical devices, especially those with patented products.',
];

export const ProvideSemanticSearchDescriptionStep = ({
  handleClose,
  setStep,
  isFirstStep,
  progress,
  userDescription,
  setUserDescription,
}: StepProps) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { handleOpen: openLoadingModal } = useModal(MODALS.SEMANTIC_SEARCH_LOADING_DIALOG);
  const { validate } = useSemanticSearchValueValidation();
  const [error, setError] = useState('');

  const trimmedDescription = userDescription?.trim() ?? '';

  const { isFetching: isSemanticRefiningLoading, refetch } = useQueryRefineSemanticDescription({
    description: trimmedDescription,
    enabled: false,
    onSuccess: (response) => {
      if (!response.improved_description) {
        setError("Sorry, Helix hasn't been able to understand your description. Please re-write it and try again.");

        return;
      }

      if (response.bad_references?.filter((ref) => !['', 'None', ' '].includes(ref)).length) {
        setError(
          'It looks like you’ve mentioned Geographies or Revenue. Helix can’t recognise these terms yet, remove them. You can filter your results later.',
        );

        return;
      }

      if (tryAskingOptions.includes(trimmedDescription)) {
        dispatch(searchActions.setSemanticSearch({ userDescription: trimmedDescription, applyDescription: false }));
        handleClose();
        push(ROUTES.CUSTOM_SEARCH_SEMANTIC);
        openLoadingModal();
      }

      setStep((prevState) => prevState + 1);
    },
  });

  const onNext = () => {
    const { validated, errorMessage } = validate(userDescription, true);

    setError(errorMessage);

    if (!validated || !trimmedDescription) return;

    refetch();
  };

  const numberOfCharacters = userDescription?.trim().length ?? 0;

  return (
    <>
      <WorkflowDialogHeader title="What do these companies do?" />
      <div className="mx-auto w-1/3 min-w-[650px] text-sm font-normal text-[#484848] flex flex-col flex-grow">
        <span>
          Tell us what these companies do and we&apos;ll use AI to craft a summary description, draw out industries &
          keywords you can adjust.
        </span>
        <span className="mt-6 mb-10 bg-[#ECF6FD] p-2 rounded-lg">
          <IconInfo className="w-4 inline mr-1" /> Try not to mention locations, financials and what companies
          don&apos;t do.
        </span>

        <TextField
          placeholder="E.g. Companies specializing in artificial intelligence (AI) and machine learning (ML) solutions.
&#8205;
Manufacturers of renewable energy equipment, such as solar panels or wind turbines.
&#8205;
Companies with expertise in pharmaceuticals or medical devices, especially those with patented products."
          variant="outlined"
          size="small"
          multiline
          rows={3}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            className: 'p-4',
          }}
          id="provide-semantic-search-description-step-textarea"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUserDescription?.(event.target.value);
            setError('');
          }}
          value={userDescription}
          className={classNames('[&>div]:rounded-lg ', {
            '[&>div>fieldset]:border-[#D63333]': !!error,
          })}
        />
        <div className="flex justify-between mt-2 gap-[80px]">
          <span className="text-[#D63333] text-sm min-h-10">
            {error ? (
              <>
                <IconWarning
                  className="w-4 inline-block mr-1"
                  fill="#D63333"
                />
                {error}
              </>
            ) : null}
          </span>
          <span className="text-[#666] text-xs">{numberOfCharacters}</span>
        </div>
        <div className="text-xs font-normal text-[#484848]">
          <span className="mt-5 mb-4 block">Try asking</span>

          {tryAskingOptions.map((tryAskingOption) => (
            <button
              key={tryAskingOption}
              onClick={() => {
                setUserDescription?.(tryAskingOption);
                setError('');
              }}
              className="px-[10px] py-1 mb-3 border border-[#484848] rounded-full text-left hover:bg-[#2E3F4C] hover:text-white transition"
            >
              {tryAskingOption}
            </button>
          ))}
        </div>
      </div>
      <WorkflowDialogFooter
        onPrimaryButtonClick={onNext}
        onBackButtonClick={isFirstStep ? handleClose : () => setStep((prevState) => prevState - 1)}
        isLoading={isSemanticRefiningLoading}
        progress={progress}
        primaryButtonId="provide-semantic-search-description-step-next-button"
      />
    </>
  );
};
