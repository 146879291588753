import { useEffect, useMemo, useState } from 'react';

import { QueryType } from '../../../../slices/search';
import { useShallowSelector } from '../../../../hooks/use-shallow-selector';

import { useQueryAutocompleteCompanySearch } from '@/hooks/queries/match-company-search/use-query-autocomplete-company-search';

interface UseMatchCompanySearchProps {
  searchText: string;
  isFocused: boolean;
  queryTypeParam?: QueryType;
}

const useAutocompleteCompanySearch = ({ searchText, isFocused, queryTypeParam }: UseMatchCompanySearchProps) => {
  const [matchCompanySearchEnabled, setMatchCompanySearchEnabled] = useState(false);
  const { queryType } = useShallowSelector((state) => state.search);
  const isMatchQuery = (queryTypeParam || queryType) === QueryType.MATCH;

  const { data: matchCompaniesList, isFetching: isMatchCompanySearchLoading } = useQueryAutocompleteCompanySearch(
    searchText,
    matchCompanySearchEnabled && isMatchQuery && isFocused,
    setMatchCompanySearchEnabled,
  );

  useEffect(() => {
    setMatchCompanySearchEnabled(false);
    const timer = setTimeout(() => setMatchCompanySearchEnabled(true), 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const result = useMemo(() => ({
    isMatchCompanySearchLoading,
    matchCompanyTypeAheadList: matchCompaniesList ?? [],
  }), [isMatchCompanySearchLoading, matchCompaniesList]);

  return result;
};

export default useAutocompleteCompanySearch;
