import { Tab as TabType } from '../../types';
import { DatumDatum } from '@/types';

export const isSentimentEnabled = (params?: { row: DatumDatum }) => {
  const allowedRegions = ['North America', 'Europe'];
  const allowedCountries = ['India', 'Australia', 'New Zealand'];

  if (!params || !params.row) {
    return false;
  }

  const region = params.row['self_location_region___'];
  const country = params.row['self_location_country___'];
  const revenue = params.row['self_financials_revenue___'];

  const isAllowedLocation =
    (region && allowedRegions.includes(region)) ||
    (country && allowedCountries.includes(country));

  const hasRequiredRevenue = typeof revenue === 'number' && revenue > 50_000_000;

  return isAllowedLocation && hasRequiredRevenue;
}

export const isTabEnabled = (tab: TabType, params?: { row: DatumDatum }) => {
  if (tab.id === 'sentiment') {
    return isSentimentEnabled(params);
  }

  return !!tab.components?.length;
};
