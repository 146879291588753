import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';
import classnames from 'classnames';

import { Tab as TabType } from '../../types';
// import { Highlights } from './highlights';
import { GenericTableTab } from '../GenericTableTab/GenericTableTab';
import { PivotTableTab } from '../PivotTableTab/PivotTableTab';
import { PeopleCharts } from './charts';
import { Sentiment } from './sentiment';
import { isTabEnabled } from './utils';
import { ChartConfigType, DatumDatum } from '@/types';

interface IProps {
  bainId: string;
  tabs: TabType[];
  section: string;
  params?: { row: DatumDatum };
  chartConfigs: ChartConfigType[];
}

export const PeopleTab = ({ bainId, section, chartConfigs, tabs, params }: IProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const subSection = `${section}:${tabs[currentTab].label}`;

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    setCurrentTab(value);
  };

  const activeTabConfig = tabs[currentTab];

  return (
    <div>
      <Tabs
        value={currentTab}
        id="people-tab-container"
        onChange={handleChange}
        variant="fullWidth"
        className="border border-[#2e3f4c] rounded min-h-[30px]"
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
      >
        {tabs.map((tab, idx) => {
          const isDisabled = !isTabEnabled(tab, params);

          return (
            <Tab
              key={tab.id}
              label={tab.label}
              id={tab.id}
              disabled={isDisabled}
              className={classnames('text-sm p-0 min-h-[30px] font-medium border-solid	border-[#2e3f4c]', {
                'opacity-50': isDisabled,
                'bg-[#2e3f4c] text-white font-semibold': currentTab === idx,
                'text-[rgb(46,63,76)]': currentTab !== idx,
                'border-r': tabs.length !== idx + 1,
              })}
            />
          )
        })}
      </Tabs>

      {/* <Highlights /> */}

      <div className="mt-4">
        <Alert
          severity="info"
          className="bg-[#ecf6fd] "
        >
          Our Workforce Insights are generated from publicly available online data. There may be discrepancies between
          the employee counts shown in our charts and those reported in companies&apos; annual reports. Please note that
          data coverage varies by industry and geography.
        </Alert>
      </div>

      {subSection !== `${section}:Sentiment` && (
        <PeopleCharts
          bainId={bainId}
          section={subSection}
          chartConfigs={chartConfigs.filter((item) => item.SECTION === subSection)}
        />
      )}

      {activeTabConfig?.components?.map(({ component, properties }, index) => {
        if (component === 'GenericTableTab' && properties && 'dataset' in properties) {
          return (
            <GenericTableTab
              key={component + index}
              bainId={bainId}
              dataset={''}
              {...properties}
            />
          );
        }

        if (
          component === 'PivotTableTab' &&
          properties &&
          'dataset' in properties &&
          'pivotColumn' in properties &&
          'valueColumn' in properties
        ) {
          return (
            <PivotTableTab
              key={component + index}
              bainId={bainId}
              dataset={''}
              pivotColumn={''}
              valueColumn={''}
              {...properties}
            />
          );
        }

        if (component === 'Sentiment') {
          return (
            <Sentiment
              key={component + index}
              bainId={bainId}
            />
          );
        }
      })}
    </div>
  );
};
