import React, { useState } from 'react';
import classNames from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from '@mui/material';
import { StarterPackWizardStepItem } from '@/types';

export interface WizardDialogCollapsibleFiltersSectionProps {
  items: StarterPackWizardStepItem[];
  sectionName: string;
  renderStepItem: (item: StarterPackWizardStepItem) => void;
}

export const WizardDialogCollapsibleFiltersSection = ({
  sectionName,
  items,
  renderStepItem,
}: WizardDialogCollapsibleFiltersSectionProps) => {
  const [showItems, setShowItems] = useState(false);

  return (
    <div className="mt-5">
      <button
        className="text-[#0484E7] text-sm font-normal underline"
        onClick={() => setShowItems((prevState) => !prevState)}
      >
        <span className="text-sm font-normal">{showItems ? `Hide ${sectionName}` : `Show ${sectionName}`}</span>
        <KeyboardArrowDownIcon
          className={classNames('fill-[#0484E7] text-xl transition ml-1', { 'rotate-180': showItems })}
        />
      </button>
      <Collapse
        in={showItems}
        timeout="auto"
        unmountOnExit
      >
        {items.map(renderStepItem)}
      </Collapse>
    </div>
  );
};
