import { post } from 'aws-amplify/api';

import {
  IGroupedAutocompleteRankReqCompanySearch,
  IGroupedAutocompleteReqCompanySearch,
  IGroupedAutocompleteResponseCompanySearchItem,
  IReqCompanySearch,
  IResponseCompanySearchItem,
} from '@/types';

export const autocompleteCompanySearch = ({
  company,
  currency,
}: IReqCompanySearch): Promise<IResponseCompanySearchItem[]> =>
  // @ts-ignore
  post({
    apiName: 'CDPAPI',
    path: `/search/autocomplete?currency=${currency}&granular=true`,
    options: { body: { company_search: company } },
  })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((res) => res?.data);

export const groupedAutocompleteCompanySearch = ({
  company,
  currency,
  bain_id,
}: IGroupedAutocompleteReqCompanySearch): Promise<IGroupedAutocompleteResponseCompanySearchItem[]> =>
  // @ts-ignore
  post({
    apiName: 'CDPAPI',
    path: `/search/autocomplete/clustered?currency=${currency}&granular=true`,
    options: { body: { company_search: company, ...(bain_id ? { bain_id } : {}) } },
  })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((res) => res?.data);

export const groupedAutocompleteRankCompanySearch = ({
  currency,
  company,
  industry,
}: IGroupedAutocompleteRankReqCompanySearch): Promise<IGroupedAutocompleteResponseCompanySearchItem> =>
  // @ts-ignore
  post({
    apiName: 'CDPAPI',
    path: `/rank_search?currency=${currency}`,
    options: { body: { company_name: company, company_industry: industry } },
  })
    .response.then((res) => res.body.json())
    // @ts-ignore
    .then((res) => res);
