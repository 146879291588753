import { isEmpty, isNil, trim } from 'lodash';
import { getEffectiveIncludeNullList, getFilters } from '@/Utils/filters';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { QueryType } from '@/slices/search';
import { IReqElastic } from '@/types';

export const useObligatoryElasticBody = (): IReqElastic => {
  const sortModel = useShallowSelector((state) => state.table.sortModel);
  const currency = useShallowSelector((state) => state.config.currency);
  const sortKey = sortModel?.[0]?.field ?? null;
  const sortOrder = sortModel?.[0]?.sort ?? null;
  const { searchQuery, queryType, searchText, semanticSearch, table_filters } = useShallowSelector(
    (state) => state.search,
  );
  const storeFilters = useShallowSelector((state) => state.filters);
  const filters = getFilters(storeFilters) as Record<string, string[]>;

  const customAggregationString = useShallowSelector((state) => state.filters.customAggregationString);
  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);
  const isKeywordQuery = queryType === QueryType.KEYWORD;
  const isSmartQuery = queryType === QueryType.SMART;
  const is_lucene = isKeywordQuery || isSmartQuery;
  const company_search = is_lucene ? trim(searchQuery) : trim(searchText);
  const effectiveIncludeNullList = getEffectiveIncludeNullList(includedNullList, storeFilters);

  return {
    currency,
    body: {
      company_search,
      is_lucene,
      filters,
      ...(!isNil(sortKey) && { sort_key: sortKey }),
      ...(!isNil(sortOrder) && { sort_order: sortOrder }),
      ...(!isNil(customAggregationString) && { custom_agg: customAggregationString }),
      ...(!isNil(semanticSearch?.userDescription) && semanticSearch?.applyDescription
        ? { description: semanticSearch.userDescription }
        : {}),
      ...(!isEmpty(effectiveIncludeNullList) && { include_null_list: effectiveIncludeNullList }),
      ...(!isEmpty(table_filters) && { op_filters: table_filters }),
    },
  };
};
