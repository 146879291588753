import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { ChartLegendItem } from '@/types';
import { CompareChartsType } from '@/types/state/charts';
import { useQueriesChartCompany } from '@/hooks/queries';

interface IProps {
  bainId: string;
  chartId: number;
  groupedLegends: Map<string, ChartLegendItem[]>;
  onRemoveDataset: (data: CompareChartsType) => void;
}

export const CustomLegend = ({ bainId: currentBainId, chartId, groupedLegends, onRemoveDataset }: IProps) => {
  const { data: companies } = useQueriesChartCompany(currentBainId);

  return (
    <div className="flex flex-col gap-3 mb-3">
      {Array.from(groupedLegends).map(([bainId, legends], idx) => (
        <div key={bainId} className="flex gap-3 items-center">
          <span className="text-xs font-medium">{companies[bainId]}</span>

          {legends.map((legend, index) => (
            <div className="flex gap-1 items-center text-xs" key={`${legend.id}_${index}`}>
              <span
                className="w-[16px] h-[16px] rounded"
                style={{
                  backgroundColor: legend.color,
                  borderColor: legend.color,
                }}
              />

              <span className="legend-text">{legend.text}</span>
            </div>
          ))}

          {idx > 0 && (
            <div className="flex items-center">
              <IconButton size="small" onClick={() => onRemoveDataset({ chartId, bainId })} >
                <CloseIcon className="w-3	h-3" />
              </IconButton>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
