import React from 'react';

export const NoData = () => {
  return (
    <div className="flex items-center flex-col gap-8 py-8">
      <div className="w-[80px] h-[80px]">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 80 84">
          <path fill="#3F3D56" d="M49.755 60.489C66.46 60.489 80 46.949 80 30.245S66.46 0 49.755 0C33.052 0 19.511 13.54 19.511 30.244S33.051 60.49 49.755 60.49Z" />
          <path fill="#000" d="M49.755 55.212c13.79 0 24.968-11.178 24.968-24.968 0-13.789-11.178-24.967-24.968-24.967-13.789 0-24.967 11.178-24.967 24.967 0 13.79 11.178 24.968 24.967 24.968Z" opacity=".05" />
          <path fill="#000" d="M49.755 50.708c11.302 0 20.464-9.162 20.464-20.463 0-11.302-9.162-20.464-20.464-20.464-11.301 0-20.463 9.162-20.463 20.464 0 11.301 9.162 20.463 20.463 20.463Z" opacity=".05" />
          <path fill="#000" d="M49.755 44.916c8.104 0 14.672-6.568 14.672-14.671 0-8.104-6.568-14.672-14.672-14.672-8.102 0-14.671 6.569-14.671 14.672 0 8.103 6.569 14.671 14.671 14.671Z" opacity=".05" />
          <path fill="#D0CDE1" d="M19.779 32.951s-2.378 6.693-1.321 9.071c1.057 2.378 2.73 4.668 2.73 4.668s-.617-13.298-1.41-13.739Z" />
          <path fill="#C00" d="M19.779 32.951s-2.378 6.693-1.321 9.071c1.057 2.378 2.73 4.668 2.73 4.668s-.617-13.298-1.41-13.739Z" opacity=".8" />
          <path fill="#DDD" d="M21.452 48.451s-.176 1.673-.264 1.761c-.088.088.088.265 0 .529s-.176.616 0 .704c.176.088-.969 7.838-.969 7.838s-2.818 3.7-1.673 9.512l.352 5.9s2.73.176 2.73-.792c0 0-.176-1.145-.176-1.674 0-.528.44-.528.176-.792s-.264-.44-.264-.44.44-.353.352-.441c-.088-.088.793-6.34.793-6.34s.969-.97.969-1.498v-.528s.44-1.145.44-1.233c0-.088 2.378-5.46 2.378-5.46l.969 3.874 1.056 5.549s.529 5.02 1.586 6.957c0 0 1.849 6.34 1.849 6.165 0-.176 3.082-.617 2.994-1.41-.088-.792-1.85-11.889-1.85-11.889l.441-16.468-11.889.176Z" />
          <path fill="#2F2E41" d="M19.074 74.255s-2.378 4.668-.792 4.844c1.585.176 2.201.176 2.906-.529.385-.385 1.165-.902 1.793-1.291a2.32 2.32 0 0 0 1.1-2.205c-.046-.431-.207-.786-.603-.82-1.057-.087-2.29-1.056-2.29-1.056l-2.114 1.057Zm13.122 3.435s-2.377 4.667-.792 4.843c1.585.176 2.201.176 2.906-.528.385-.385 1.165-.902 1.793-1.291a2.319 2.319 0 0 0 1.1-2.206c-.046-.43-.207-.785-.603-.819-1.057-.088-2.29-1.056-2.29-1.056l-2.114 1.056Z" />
          <path fill="#FFB8B8" d="M29.683 25.313a3.702 3.702 0 1 0 0-7.404 3.702 3.702 0 0 0 0 7.404Z" />
          <path fill="#FFB8B8" d="M27.303 22.827s-2.644 4.866-2.855 4.866c-.212 0 4.76 1.586 4.76 1.586s1.374-4.654 1.586-5.077l-3.49-1.375Z" />
          <path fill="#C00" d="M31.36 28.151s-5.284-2.906-5.813-2.818c-.528.088-6.164 5.02-6.076 7.046.088 2.025.792 5.372.792 5.372s.264 9.335.793 9.423c.528.088-.088 1.673.088 1.673s12.33 0 12.417-.264c.089-.264-2.201-20.431-2.201-20.431Z" />
          <path fill="#FFB8B8" d="M34.31 49.068s1.673 5.108.264 4.931c-1.409-.176-2.025-4.403-2.025-4.403l1.76-.528Z" />
          <path fill="#C00" d="M29.819 27.843s-3.259.705-2.73 5.108c.528 4.404 1.497 8.807 1.497 8.807l3.258 7.133.352 1.321 2.378-.616-1.761-10.216s-.617-10.92-1.41-11.273a3.412 3.412 0 0 0-1.584-.264Z" />
          <path fill="#000" d="m27.837 41.626 4.051 7.221-3.413-7.61-.638.389Z" opacity=".1" />
          <path fill="#2F2E41" d="m33.369 20.52.012-.282.563.14a.629.629 0 0 0-.252-.463l.6-.034a6.467 6.467 0 0 0-4.327-2.673c-1.296-.188-2.74.084-3.63 1.046-.43.467-.702 1.06-.894 1.666-.355 1.115-.428 2.445.313 3.351.752.922 2.066 1.102 3.25 1.216.417.04.854.078 1.24-.084a2.981 2.981 0 0 0-.166-1.308.871.871 0 0 1-.088-.416c.052-.352.522-.441.875-.393.353.047.777.12 1.01-.15.16-.187.15-.458.17-.702.058-.666 1.318-.774 1.324-.913Z" />
        </svg>
      </div>

      <span className="text-[#666] text-sm">No Glassdoor data was found for this company.</span>
    </div>
  );
};
