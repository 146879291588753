import React, { useCallback, useMemo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import { debounce, Dictionary, groupBy, without } from 'lodash';

import { useDispatch } from 'react-redux';
import { groupAndSortList } from '@/Utils';
import { useQueryColumnMapper } from '@/hooks/queries/column-mapper/use-query-column-mapper';
import { IColumnMapper } from '@/types';
import { actions } from '@/slices/table';
import { CHECK_COLUMN_NAME } from '@/constants';
import { Switch } from '@/Components/Shared/Switch/Switch';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useQueryConfig } from '@/hooks/queries/config/use-query-config';
import { Loader } from '@/Components/Shared/Loader/Loader';
import { Link } from '@/Components/Shared/Link';
import { SOURCE_LINK } from '@/Components/Header/ProfileDropdown';

interface BasicColumn {
  section: string;
  headerName?: string;
  hide?: boolean;
  field?: string;
  tooltip?: string;
}

const FIELDS_TO_BE_OMITTED = [CHECK_COLUMN_NAME, 'id'];

type SectionData = [string, BasicColumn[]];

const ColumnsVisibilityPanel = () => {
  const dispatch = useDispatch();
  const columnMapperQuery = useQueryColumnMapper();
  const columnMapper = useMemo(() => columnMapperQuery.data ?? [], [columnMapperQuery.data]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [expandedSections, setExpandedSections] = React.useState<string[]>([]);
  const visibleColumns = useShallowSelector((state) => state.table.visible);
  const filterColums = useShallowSelector((state) => state.table.filterColumns);
  const apiRef = useGridApiContext();
  const columns = apiRef.current.getAllColumns();
  const columnsVisibility = useMemo(() => visibleColumns.concat(filterColums), [visibleColumns, filterColums]);

  const handleChange = (section: string) => {
    setExpandedSections((prevState) => {
      if (prevState.includes(section)) return without(prevState, section);

      return [...prevState, section];
    });
  };

  const filterColumns = useCallback(
    (allCols: SectionData[], searchVal: string) =>
      !searchVal
        ? allCols
        : allCols.reduce((res, [section, cols]) => {
            const filteredCols = cols.filter((x) => x.headerName?.toLowerCase().includes(searchVal));

            if (filteredCols.length) {
              res.push([section, filteredCols]);
            }

            return res;
          }, [] as SectionData[]),
    [],
  );

  const getSectionByField = useCallback(
    (field: string, groupedColumnMapper: Dictionary<IColumnMapper[]>) =>
      groupedColumnMapper[field]?.[0]?.['Display Header'] || 'Others',
    [],
  );

  const getTooltipTextByField = useCallback(
    (field: string, groupedColumnMapper: Dictionary<IColumnMapper[]>) =>
      groupedColumnMapper[field]?.[0]?.['Column Metadata'] ?? '',
    [],
  );

  const groupedColumnMapper = useMemo(() => groupBy(columnMapper, 'Backend Name'), [columnMapper]);
  const { data: columnMapperOrderData, isLoading: isColumnMapperOrderLoading } = useQueryConfig<{
    section_order: string[];
  }>('section_order');

  const columnMapperOrder = useMemo(
    () => columnMapperOrderData?.section_order ?? [],
    [columnMapperOrderData?.section_order],
  );

  const handleToggleColumn = (field: string, checked: boolean) => {
    apiRef.current.setColumnVisibility(field, checked);

    if (!checked && !visibleColumns.includes(field)) {
      dispatch(actions.toggleFilterColumns([field]));
    }
  };

  const handleResetToDefault = () => {
    const fields = columnMapper
      .filter(({ DEFAULT_COLUMN }) => !!DEFAULT_COLUMN)
      .map((column) => column['Backend Name']);

    dispatch(actions.setVisibleColumns([CHECK_COLUMN_NAME, ...fields]));
  };

  const handleShowAllColumns = () => {
    const fields = columns.map(({ field }) => field);

    dispatch(actions.setVisibleColumns(fields));
  };

  const groupedColumns = useMemo(() => {
    const columnsWithSections: BasicColumn[] = columns
      .filter((column) => column.field && !FIELDS_TO_BE_OMITTED.includes(column.field))
      .map(({ field, headerName }) => ({
        section: getSectionByField(field, groupedColumnMapper),
        headerName,
        field,
        hide: !columnsVisibility.includes(field),
        tooltip: getTooltipTextByField(field, groupedColumnMapper),
      }));

    return groupAndSortList(columnsWithSections, columnMapperOrder, 'section');
  }, [columns, columnMapperOrder, getSectionByField, groupedColumnMapper, columnsVisibility, getTooltipTextByField]);

  const handleSearch = debounce((searchVal: string) => {
    const trimmedValue = searchVal.trim().toLowerCase();
    const allSections = groupedColumns.map(([section]) => section);

    setSearchValue((prevState) => {
      if (prevState === '') {
        setExpandedSections(allSections);
      }

      if (trimmedValue === '') {
        setExpandedSections([]);
      }

      return trimmedValue;
    });
  }, 300);

  const filteredColumns = useMemo(
    () => (searchValue ? filterColumns(groupedColumns, searchValue) : groupedColumns),
    [filterColumns, groupedColumns, searchValue],
  );

  return (
    <Box
      className="flex flex-col max-w-5xl relative my-2 mx-2.5 min-w-[620px]"
      data-testid="column-visibility-panel-wrapper"
    >
      {isColumnMapperOrderLoading ? (
        <div className="flex justify-center items-center min-h-[440px] overflow-y-auto">
          <Loader />
        </div>
      ) : (
        <>
          <TextField
            onChange={(e: React.ChangeEvent<{ value: string }>) => handleSearch(e.target.value)}
            label="Find column"
            type="search"
            variant="filled"
            size="small"
            classes={{ root: 'mb-[10px]' }}
            inputProps={{
              ['data-testid']: 'column-visibility-panel-search-input',
            }}
            InputProps={{
              className: 'text-[#666666] bg-white',
            }}
          />
          <div className="absolute pt-3 pl-0 pr-4 pb-2 bg-white top-0 right-0 z-50">
            <Link
              href={SOURCE_LINK}
              className="flex items-center pl-4"
            >
              Data source descriptions
            </Link>
          </div>
          <Box className="overflow-y-auto relative">
            {filteredColumns.map(([section, cols]) => {
              // skip empty sections to reduce clutter and skip rendering toggle for "Check"
              if (!cols) return null;

              const id = `${section}-content`;

              return (
                <Accordion
                  key={section}
                  className="shadow-none"
                  classes={{ root: 'before:h-0', expanded: 'm-0' }}
                  expanded={expandedSections.includes(section)}
                  onChange={() => handleChange(section)}
                >
                  <AccordionSummary
                    className="w-min min-h-[22px] border-0 mb-[4px] pl-[9px]"
                    classes={{ content: 'm-0' }}
                    expandIcon={<ExpandMoreIcon className="w-6 h-4" />}
                    aria-controls={id}
                    id={id}
                  >
                    <Typography
                      id={id}
                      data-testid={id}
                      className="uppercase text-[12px] text-[#484848] whitespace-nowrap"
                      classes={{ root: cols.filter((col) => !col.hide).length > 0 ? 'font-medium' : 'font-normal' }}
                    >
                      {section}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="flex flex-col">
                      {cols?.map(({ field, hide, headerName, tooltip }) => (
                        <div key={id + headerName}>
                          <Switch
                            label={headerName}
                            onToggle={(e) => handleToggleColumn(field as string, e.target.checked)}
                            isChecked={!hide}
                            dataTestId="column-visibility-toggle"
                            labelPlacement="end"
                            tooltip={tooltip}
                          />
                        </div>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
          <Box className="flex justify-between pt-2 pb-1">
            <Button
              variant="text"
              onClick={handleResetToDefault}
              className="normal-case text-[14px] font-normal text-[#2E3F4C]"
            >
              Reset To Default
            </Button>
            <Button
              variant="text"
              onClick={handleShowAllColumns}
              className="normal-case text-[14px] font-normal text-[#2E3F4C]"
            >
              Show All
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ColumnsVisibilityPanel;
