import React, { useMemo } from 'react';

import { GridColDef } from '@mui/x-data-grid-pro';
import { reduce } from 'lodash';
import { GenericTable } from '../GenericTableTab/GenericTable';
import { OrderTypes } from './MultiyearFinTab';
import { Currency, FinancialDataSourceType, IMultiYearGroup } from '@/types';
import { useQueryColumnMapperDataset } from '@/hooks/queries/column-mapper/use-query-column-mapper-dataset';
import { getRowBasedColumn } from '@/Components/Shared/ScreenerTable/columns';

export const formatTableTitle = (title: string) => {
  // TODO: read from config
  switch (title) {
    case 'Annual_BS':
      return 'Annual Balance Sheet';
    case 'Annual_IS':
      return 'Annual Income Statement';
    case 'Quarterly_BS':
      return 'Quarterly Balance Sheet';
    case 'Quarterly_IS':
      return 'Quarterly Income Statement';

    default:
      return 'Other';
  }
};

const getColumnWidths = (field: string) => {
  if (field === 'metric') {
    return { flex: 1, minWidth: 180 };
  }

  if (field === 'units') {
    return { flex: 0.4, minWidth: 100 };
  }

  return { flex: 0.5, minWidth: 120 };
};

export interface MultiyearFinTableProps {
  tableName: string;
  tableData: IMultiYearGroup;
  currentDataSource: FinancialDataSourceType;
  currency: Currency;
  order?: OrderTypes;
  isEstimated?: boolean;
}

export const MultiyearFinTable = ({
  tableName,
  tableData,
  currentDataSource,
  currency,
  order,
  isEstimated,
}: MultiyearFinTableProps) => {
  const columnMapper = useQueryColumnMapperDataset('master_time_series');
  // With current (2023-12) column mapper structure, we have single precision for all values.
  const numericPrecision =
    columnMapper.data?.find((column) => column['Backend Name'] == 'value')?.NUMERIC_PRECISION || undefined;

  const descOrder = order === 'desc';

  const { columns, rows } = useMemo(() => {
    const rawColumns: GridColDef[] = [];
    const rawRows = [] as Record<string, unknown>[];

    Object.entries(tableData).forEach((dataPoint) => {
      const field = dataPoint[0];

      if (field != 'statement') {
        let headerName = dataPoint[0].toUpperCase();

        if (headerName[4] == 'Q') {
          headerName = headerName.substring(0, 4) + ' ' + headerName.substring(4, headerName.length);
        }

        const column = getRowBasedColumn({
          field,
          displayName: headerName,
          ...getColumnWidths(field),
          pinnable: false,
          numericPrecision,
          currency,
        });

        rawColumns.push({ ...column, cellClassName: 'pl-7', headerClassName: 'pl-7' });

        const rowVals = Object.values(dataPoint[1]);
        let row = {};
        let x = 0;
        const newKey = [dataPoint[0]];

        rowVals.forEach((rv) => {
          if (rawRows[x] && 'id' in rawRows[x] && rawRows[x].id == x + 1) {
            //@ts-ignore
            rawRows[x][newKey] = rv;
            x++;
          } else {
            x++;
            row = {
              id: x,
              //@ts-ignore
              [newKey]: rv,
            };
            rawRows.push(row);
          }
        });
      }
    });

    rawColumns.sort(function (a, b) {
      const compareVal1 = a['field'];
      const compareVal2 = b['field'];
      const firstColumns = ['metric', 'units', 'data_source'];
      const compareVal1Index = firstColumns.indexOf(compareVal1);
      const compareVal2Index = firstColumns.indexOf(compareVal2);

      if (compareVal1Index !== -1 && compareVal2Index !== -1) {
        // both string columns, sort base on position
        return compareVal1Index - compareVal2Index;
      } else if (compareVal1Index === -1 && compareVal2Index === -1) {
        // both year columns, lexographic ordering
        if (descOrder) return compareVal1 < compareVal2 ? 1 : -1;

        return compareVal1 < compareVal2 ? -1 : 1;
      } else {
        // one is year column, one is text column
        if (compareVal1Index === -1) return 1;
        else return -1;
      }
    });

    return {
      columns: rawColumns,
      rows: rawRows,
    };
  }, [currency, descOrder, numericPrecision, tableData]);

  const columnVisibilityModel = useMemo(
    () => reduce(columns, (acc, { field }) => ({ ...acc, [field]: field !== 'data_source' }), {}),
    [columns],
  );

  const filteredRows = rows.filter(({ data_source }) => data_source === currentDataSource);

  if (!filteredRows || filteredRows.length === 0) {
    return null;
  }

  return (
    <GenericTable
      tableName={formatTableTitle(tableName)}
      columns={columns}
      rows={filteredRows}
      filterModel={{
        items: [
          {
            field: 'data_source',
            operator: 'equals',
            value: currentDataSource,
            id: 'dropdown-filter',
          },
        ],
      }}
      pinnedColumns={{ left: ['metric', 'units', 'data_source'] }}
      initialColumnVisibilityModel={columnVisibilityModel}
      isEstimated={isEstimated}
    />
  );
};
