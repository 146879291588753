import React from 'react';
import { useQueriesChartData } from '@/hooks/queries';
import { ChartConfigType, ChartDatasetType } from '@/types';
import { ChartCommon } from '@/Components/Shared/Charts';

interface IProps {
  bainId: string;
  section: string;
  chartConfigs: ChartConfigType[];
}

export const PeopleCharts = ({ bainId, section, chartConfigs }: IProps) => {
  const queries = useQueriesChartData(chartConfigs, bainId, section);
  const isFetched = queries.every((item) => !item.isFetching);
  const isEmpty = isFetched && queries.every((item) => item.data?.data?.length === 0);

  return (
    <div className="grid gap-4 mt-4 grid-cols-2">
      {chartConfigs
        .filter((_, idx) => idx < 3)
        .map((config, idx) => {
          const chartData = queries[idx].data;

          if (chartData?.data.length === 0) return;

          return (
            <ChartCommon
              comparisonDatasets={[]}
              key={config.ID}
              data={chartData as ChartDatasetType}
              section={section}
              isLoading={queries[idx].isLoading}
              config={config}
            />
          );
        })}

      {isEmpty && <div>Charts are currently unavailable due to insufficient data.</div>}
    </div>
  );
};
